class TrigramIndex {
  constructor(inputPhrases = []) {
    this.ids = []
    this.phrases = []
    this.trigramIndex = []
    this.allowDuplicatePhrases = true
    inputPhrases.forEach(phrase => { this.index(...phrase) })
  }

  normalize(string) {
    return string
      .toLowerCase()
      .replace(/[^\x00-\x7F]/g, "")
      .replace(/[^a-z\d]/g, " ")
      .replace(/\s+/g, "*")
      .replace(/^/g, "**")
      .replace(/$/g, "*")
  }

  asTrigrams(phrase, callback) {
    const rawData = this.normalize(phrase)

    for( let i = rawData.length - 3; i >= 0; i = i - 1 ) {
      callback(rawData.slice(i, i + 3))
    }
  }

  index(phrase, id) {
    if(Array.isArray(phrase)) {
      [phrase, id] = phrase
    }
    phrase = phrase.toLowerCase()

    if(!phrase || phrase === "" || (!this.allowDuplicatePhrases && this.phrases.indexOf(phrase) >= 0)) return
    const phraseIndex = this.phrases.push(phrase) - 1
    this.ids.push(id)

    this.asTrigrams(phrase, trigram => {
      let phrasesForTrigram = this.trigramIndex[`t_${trigram}`]
      if(!phrasesForTrigram) phrasesForTrigram = []
      if(phrasesForTrigram.indexOf(phraseIndex) < 0) phrasesForTrigram.push(phraseIndex)
      this.trigramIndex[`t_${trigram}`] = phrasesForTrigram
    })
  }

  find(phrase) {
    const phraseMatches = []
    let trigramsInPhrase = 0
    // const start = Date.now()

    this.asTrigrams(this.normalize(phrase), trigram => {
      const phrasesForTrigram = this.trigramIndex[`t_${trigram}`]
      trigramsInPhrase += 1
      if(phrasesForTrigram) {
        for(let j in phrasesForTrigram) {
          const phraseIndex = phrasesForTrigram[j]
          if(!phraseMatches[phraseIndex]) phraseMatches[phraseIndex] = 0
          phraseMatches[phraseIndex] += 1
        }
      }
    })

    const result = []
    for(let i in phraseMatches) {
      result.push({ id: this.ids[i], phrase: this.phrases[i], matches: phraseMatches[i] })
    }


    result.sort((a, b) => {
      const diff = b.matches - a.matches
      return diff// == 0 ? a.phrase.localeCompare(b.phrase) : diff
    })
    // console.log(Date.now() - start)
    return result
  }
}

export { TrigramIndex }
