import { WorldLayer } from "../world_layer"

class IslandLocationData {
  constructor(parent, url) {
    this.parent = parent
    this.notifyTargets = [parent]
    this.url = url
    this.isldata = {}
  }

  get available() { return !!this.url }

  static RENDER_ORDER = [
    "landmark_treasure",
    "sunken",
    "burried",
    "underground",
    "ai_spawn",
    "washed_up",
    "lore_book_items_data_asset",
  ]

  enqueue(islid) {
    if(!this.url) return false
    if(this.isldata[islid] && !this.isldata[islid].failed) return this.isldata[islid]
    this.isldata[islid] = {
      loading: true,
      loaded: false,
      failed: false,
      data: null,
    }

    this.isldata[islid].fetchPromise = fetch(`${this.url}${this.url.includes("?") ? "&" : "?"}ilocs=${islid}`).then(r => r.ok ? r.json() : Promise.reject(r)).then(j => {
      this.isldata[islid].loading = false
      this.isldata[islid].loaded = true
      this.isldata[islid].data = j
      this.isldata[islid].sortedKeys = Object.keys(j).toSorted((a, b) => {
        return this.constructor.RENDER_ORDER.indexOf(a) - this.constructor.RENDER_ORDER.indexOf(b)
      })

      const island = this.parent.world.islands.find(islid)
      const ipos = island.position ?? [0, 0]
      const rdelta = -90 + island.rotation
      const cpos = island.cameraPosition

      // offset by camera position & rotate if necessary
      Object.entries(this.isldata[islid].data).forEach(([key, value]) => {
        if(!rdelta && !cpos) return false

        if(key == "landmark_treasure") {
          const list = Object.entries(value)
          list.forEach(([lmname, coords], i) => {
            value[lmname] = coords.map(p => {
              if(cpos) p = [p[0] - cpos[0], p[1] - cpos[1], 0]
              if(rdelta) p = this.parent.rotateCoordinates(p, rdelta)
              p[0] += ipos[0]
              p[1] += ipos[1]
              return p
            })
            value[lmname].color = this.parent.rgbToHex(...this.parent.hsvToRgb((i/list.length) * 0.9, 1, 1))
          })
        } else {
          this.isldata[islid].data[key] = value.map(p => {
            if(cpos) p = [p[0] - cpos[0], p[1] - cpos[1], 0]
            if(rdelta) p = this.parent.rotateCoordinates(p, rdelta)
            p[0] += ipos[0]
            p[1] += ipos[1]
            return p
          })
        }
      })

      this.notifyTargets.forEach(nt => nt.update())
    }).catch(e => {
      this.isldata[islid].loading = false
      this.isldata[islid].failed = e.status !== 404
      this.parent.toastNotification(`Failed to load island location data: ${e.status ?? ""} ${e.statusText ?? ""} ${e.message ?? ""}`)
      console.error("failed to load isldata for ", islid, e)
    })

    return this.isldata[islid]
  }

  dataFor(islid, enqueue = false) {
    if(!this.isldata[islid]) {
      if(enqueue) this.enqueue(islid)
      return false
    }
    if(!this.isldata[islid].loaded) return false
    return [this.isldata[islid].sortedKeys, this.isldata[islid].data]
  }
}

export class IslandLocations extends WorldLayer {
  init() {
    this.dependsOnIslands()
    this.enableInitially = false
    this.debounceRenderWithFade = 200
    this.afterOChange = "render"

    this.addC("lore_book_items_data_asset", true, { shape: "book", fill: "#992200", stroke: "#111" })
    this.addC("washed_up", true, { shape: "chest", fill: "#aa00ff", stroke: "#111" })
    this.addC("sunken", false, { color: "blue", shape: "x" })
    this.addC("burried", false, { color: "#7B3F00" })
    this.addC("underground", false, { color: "#5C4033" })
    this.addC("ai_spawn", false, { color: "orange" })
    this.addC("landmark_treasure", true, { shape: "circle", fill: "#DA70D662" })

    this.isldata = new IslandLocationData(this, this.world.world.data("fetchLocationData"))
  }

  ready() {
    this.bookIconText = this.createText("\uF193", { anchor: -0.5, font: "40px bootstrap-icons", stroke: "#111", lw: 2, strokeAfterFill: false })
    this.chestIconText = this.createText("\uF182", { anchor: -0.5, font: "40px bootstrap-icons", stroke: "#111", lw: 2, strokeAfterFill: false })
  }

  updateZoom(ev) {
    this.bookIconText.opts.font = `${Math.max(26, 300 / this.world.realScale)}px bootstrap-icons`
    this.chestIconText.opts.font = `${Math.max(20, 300 / this.world.realScale)}px bootstrap-icons`
  }

  addC(type, doRender = false, opts = {}) {
    this.optreg.add("bool", `${type}.render`, doRender)
    this.optreg.add("str", `${type}.color`, opts.color ?? (opts.shape == "circle" ? "none" : "#ff00ff"))
    this.optreg.add("str", `${type}.fill`, opts.fill ?? "none")
    this.optreg.add("str", `${type}.shape`, opts.shape ?? "+")
    this.optreg.add("int", `${type}.dim`, opts.dim ?? (opts.shape == "circle" ? 300 : 10000))
    this.optreg.add("bool", `${type}.scaled`, opts.dim ?? opts.shape != "circle")
  }

  setActiveIsland(activeIsland) {
    if(this.activeIsland) {
      // console.warn("todo: unload active island", this.activeIsland)
    }
    this.activeIsland = activeIsland
    if(!this.activeIsland) return false
    this.isldata.enqueue(this.activeIsland.id)
  }

  beforeEnable() {
    const invp = this.world.islands.fullyInView
    if(invp.length != 1) { //  || this.world.vscale < 10
      const reasons = []
      if(invp.length != 1) reasons.push(`${invp.length ? `more than one (${invp.length}) islands` : "no island"} in view`)
      // if(this.vscale < 10) reasons.push(`zoom level < 10 (${this.world.vscale})`)
      this.toastNotification(reasons.join(" and "))
      return false
    } else {
      this.setActiveIsland(invp[0])
    }
  }

  onEnable() {
  }

  onDisable() {
    this.setActiveIsland(undefined)
    this.clear()
  }

  addTestMarker(x, y, dim = 300, label, opts = {}) {
    let shape = "circle"
    let scaled = false
    let rotateOrigin = true
    let globalOrigin = false
    const optsDupe = Object.assign({}, opts)
    if("shape" in opts) { shape = opts.shape; delete optsDupe.shape }
    if("scaled" in opts) { scaled = opts.scaled; delete optsDupe.scaled }
    if("rotateOrigin" in opts) { rotateOrigin = opts.rotateOrigin; delete optsDupe.rotateOrigin }
    if("globalOrigin" in opts) { globalOrigin = opts.globalOrigin; delete optsDupe.globalOrigin }

    const oo = Object.assign({}, { fill: "red", color: "red", globalAlpha: 0.5 }, optsDupe)

    const rdelta = -90 + this.activeIsland.rotation
    const cpos = this.activeIsland.cameraPosition
    if(cpos) {
      x = x - cpos[0]
      y = y - cpos[1]
    }
    if(!globalOrigin && rotateOrigin && rdelta) [x, y] = this.rotateCoordinates([x, y], rdelta)

    this.activeIsland.customMarkers ??= []
    this.activeIsland.customMarkers.push([[x, y], label, globalOrigin, shape, dim, scaled, oo, false])
    this.update()
  }

  replaceDebugMarkers(...args) {
    this.activeIsland.customMarkers = []
    this.addDebugMarkers(...args)
  }

  addDebugMarkers(markers, dim, label, opts = {}) {
    if(Array.isArray(markers[0])) {
      markers.forEach(([x, y]) => {
        this.addTestMarker(x, y, dim, label, opts)
      })
    } else {
      markers.forEach(md => {
        const mdopt = Object.assign({}, opts, md.o ?? {})
        md.p.forEach(([x, y]) => {
          this.addTestMarker(x, y, md.r ?? dim, md.n ?? label, mdopt)
        })
      })
    }
  }

  render() {
    if(!this.enabled) return false
    this.clear()
    if(!this.activeIsland) return false
    if(!this.activeIsland.inView) return this.disable()
    const data = this.isldata.dataFor(this.activeIsland.id)

    if(data) data[0].forEach(key => {
      const ltype = key
      const ldat = data[1][key]
      const lc = this.o(ltype)

      if(!lc || !lc.get("render")) return false
      if(!(lc.get("fill") ?? lc.get("color"))) {
        console.warn("no color for ltype", ltype, "ignoring entries")
        return false
      }
      const shape = lc.get("shape")
      const dim = lc.get("dim")
      const scaled = lc.get("scaled")

      this.trx(ctx => {
        const textToRender = []
        this.applyCtxOpts(lc)

        if(ltype == "landmark_treasure") {
          Object.entries(ldat).forEach(([lmname, coords]) => {
            ctx.fillStyle = coords.color + 59
            coords.forEach((p, i) => {
              this._drawMarker(p, shape, dim, scaled, lc)
            })

            // name
            if(coords.length) {
              coords.textEl ??= this.createText(lmname.split("@")[0], { anchorX: -0.5, font: "20px Arial", lw: 3, stroke: "black", color: coords.color })
              coords.textEl.updatePos(...coords[0])
              textToRender.push(coords.textEl)
            }
          })
        } else {
          ldat.forEach(p => {
            this._drawMarker(p, shape, dim, scaled, lc)
          })
        }

        textToRender.forEach(t => t.draw())
      })
    })

    this.activeIsland.customMarkers?.forEach(([p, label, go, ...args]) => {
      const tp = go ? p : [p[0] + this.activeIsland.position[0], p[1] + this.activeIsland.position[1]]
      this._drawMarker(tp, ...args)
      if(label) {
        this.createText(`${label}`, { offsetY: -16, anchor: [-0.5, -1], font: `8px sans-serif` }).draw(...tp)
      }
    })
  }

  _drawMarker(p, type, dim = 10000, scaled = true, opts = {}, mayBare = true) {
    if(type == "+") {
      this.drawX(p, dim, scaled, opts, mayBare)
    } else if (type == "x") {
      this.drawX45(p, dim, scaled, opts, mayBare)
    } else if (type == "circle") {
      // opts.scaled ??= scaled
      this.drawCircle(p, dim, opts, mayBare)
    } else if (type == "chest") {
      this.chestIconText.draw(...p)
    } else if (type == "book") {
      this.bookIconText.draw(...p)
    } else {
      alert(`unknown type ${type}`)
    }
  }

  rotateCoordinates(p, angleDegrees) {
    // Convert angle from degrees to radians
    const angleRadians = angleDegrees * (Math.PI / 180)
    // console.log(angleDegrees, angleRadians, p)
    const cos = Math.cos(angleRadians)
    const sin = Math.sin(angleRadians)

    return [
      p[0] * cos - p[1] * sin,
      p[0] * sin + p[1] * cos,
      p[2],
    ]
  }
}
