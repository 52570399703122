import { Controller } from "@hotwired/stimulus"

// <div data-controller="content-swap" data-content-swap-default-value="showThisWhenNoTarget">
//   <select data-content-swap-target="trigger" data-action="content-swap#selectChange">
//     <option selected="selected" value="generic">Generic</option>
//     <option value="foo">Foo</option>
//     <option value="bar">Bar</option>
//   </select>

//   <div>always here</div>
//   <div data-swap-when="showThisWhenNoTarget">ifNoTarget</div>
//   <div data-swap-when="generic foo">generic & foo</div>
//   <div data-swap-when="bar">generic & foo</div>
// </div>

export default class extends Controller {
  static targets = [ "trigger" ]
  static values = {
    default: String,
  }

  connect() {}

  triggerTargetConnected(element) {
    this.invokeChangeOnElement(element)
  }

  selectChange(ev) {
    this.invokeChangeOnElement(ev.target)
  }

  invokeChangeOnElement(el) {
    let elVal = el.value
    if(el.tagName == "INPUT" && el.type.toUpperCase() == "CHECKBOX" && !el.checked) elVal = "0"
    this.valueSelected(elVal, el.dataset.swapContext)
  }

  valueSelected(swapTarget, swapContext) {
    const ctxstr = swapContext ? `[data-swap-context=${swapContext}]` : ":not([data-swap-context])"
    this.element.querySelectorAll(`[data-swap-when]${ctxstr}`).forEach(el => { el.style.display = "none" })

    let target = this.element.querySelectorAll(`[data-swap-when~="${swapTarget}"]${ctxstr}`)
    if(!target.length && this.defaultValue) {
      target = this.element.querySelectorAll(`[data-swap-when~="${this.defaultValue}"]${ctxstr}`)
    }
    target.forEach(el => { el.style.display = "" })
  }
}
