import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "ContentSwap"

  documentLoad() {

  }

  pageLoad() {
    $("[data-swap-trigger]").change(ev => {
      const ctx = $(ev.target).data("swapContext")
      const ctxstr = ctx ? `[data-swap-context=${ctx}]` : ":not([data-swap-context])"
      $(`[data-swap]${ctxstr}`).hide()

      let target = $(`[data-swap~=${$(ev.target).val()}]${ctxstr}`)
      if(!target.length && $(ev.target).data("swapDefault")) {
        target = $(`[data-swap=${defaultName}]${ctxstr}`)
      }
      target.show()
    }).change()
  }
}


AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
