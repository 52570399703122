import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Sot__RefreshTimer"

  init() {
    this.timers = []
  }

  documentLoad() {
  }

  pageLoad() {
    this.timers.forEach(t => t.destroy())
    this.timers = []

    const dim = { w: 455, h: 555, he: 555 + 260 }

    $(`[data-appos-controller="SotRefreshTimer"]`).each((i, el) => {
      const timer = new SotRefreshTimerElement(el)
      this.timers.push(timer.start())

      if (el.dataset.resizeWindow) {
        window.resizeTo(dim.w, dim.h)
        timer.setV("rawOuter", outer => {
          outer.addEventListener("toggle", ev => {
            window.resizeTo(dim.w, outer.open ? dim.he : dim.h)
          })
        })
      }
    })

    app.input?.onKeydownThisPage(ev => {
      const targetTimer = this.timers[0]
      if(!targetTimer) return true

      if(ev.key == "Backspace") {
        targetTimer.reset().render()
        ev.preventDefault()
        return false
      } else if (ev.key == ",") {
        targetTimer.setMarker().render()
        ev.preventDefault()
        return false
      } else if (ev.key == "0") {
        targetTimer.recordUnchanged().render()
        ev.preventDefault()
        return false
      } else if (ev.key == "Enter") {
        targetTimer.recordChanged().render()
        ev.preventDefault()
        return false
      } else {
        console.log("key", ev.key, ev)
      }
    })
  }
}

class SotRefreshTimerElement {
  constructor(el, opts = {}) {
    this.el = el
    this.maxTime = 30 * 60 * 1000
    this.changedInitialWindow = 5 * 1000
    this.opts = Object.assign({}, {
      clockHandlesFilter: n => { return (n + 1) % 5 != 0 }, // even
      // clockHandlesFilter: n => { return n % 2 == 1 }, // odd
      // clockHandlesFilter: n => { console.log(n, (n) % 3 != 0); return (n+1) % 3 != 0 }, // main
    }, opts)
    this.timer = null
    this.reset()
    this.createHandles()
  }

  updateElement(el) {
    this.el = el
    this.render()
    return this
  }

  createHandles(num = 60) {
    this.setV("clockFace", el => {
      ["clockHandles", "clockNotches"].forEach(what => {
        this.setV(what, ch => {
          let mdim = 0
          for (var i = 0; i < num; i++) {
            if(this.opts?.[`${what}Filter`]?.(i)) continue;
            const handle = $("<div>").addClass("position-absolute").attr("data-m", i)
            if(what == "clockHandles") {
              handle.text(i == 59 ? 0 : i+1)
            }
            $(ch).append(handle)
            mdim = Math.max(mdim, Math.max(handle.outerWidth(), handle.outerHeight()))
          }
          let coords = null
          if (what == "clockNotches") {
            coords = this.calculateCoordinates((el.offsetWidth * 0.5) - (mdim / 2))
          } else {
            coords = this.calculateCoordinates((el.offsetWidth * 0.5) - (mdim / 1))
          }
          coords.forEach((coord, i) => {
            const handle = $(ch).find(`div[data-m=${i}]`)
            let x = coord.x - (handle.outerWidth() / 2)
            let y = coord.y - (handle.outerHeight() / 2)
            handle.css({
              "top": `${(y)}px`, //  + (handle.height() * ((i * 30) % 90 / 90))
              "left": `${(x)}px`, //  + (handle.width() * ((i * 30) % 90 / 90))
            })
            if(what == "clockNotches") {
              handle.get(0)?.style?.setProperty('--rotate', `${(i + 1) * (360/num)}deg`)
            }
          })
        })
      })
    })
  }

  destroy() {
    this.stop()
  }

  reset() {
    this.ranges = new PartitionRange({ max: this.maxTime })
    this.ranges.set("unknown", 0, this.maxTime)
    this.setV("slot1", el => el.textContent = ``)
    this.setV("slot2", el => el.textContent = ``)

    this.setMarker()
    return this
  }

  setMarker() {
    this.markerAt = Date.now() % this.maxTime
    return this
  }

  recordUnchanged() {
    if(this.markerAt === null) this.setMarker()
    const rnow = Date.now() % this.maxTime
    this.ranges.set("unchanged", this.markerAt, rnow)
    return this.setMarker()
  }

  recordChanged() {
    const rnow = Date.now() % this.maxTime
    const gr = this.getRange("changed")
    const rr = this.getRange("unchanged")

    if(gr) { // we already have a green bit, resize it
      // Calculate the clockwise distances
      let d_to_a_clockwise = (gr[1] - rnow + this.maxTime) % this.maxTime
      let d_to_b_clockwise = (gr[2] - rnow + this.maxTime) % this.maxTime

      // Calculate the counterclockwise distances
      let a_to_d_counterclockwise = (rnow - gr[1] + this.maxTime) % this.maxTime
      let b_to_d_counterclockwise = (rnow - gr[2] + this.maxTime) % this.maxTime

      // Determine the shortest distances
      let distanceToA = Math.min(d_to_a_clockwise, a_to_d_counterclockwise)
      let distanceToB = Math.min(d_to_b_clockwise, b_to_d_counterclockwise)

      this.ranges.set("unchanged", 0, this.maxTime)
      if (distanceToA < distanceToB) {
        this.ranges.set("changed", rnow, gr[2])
      } else if (distanceToB < distanceToA) {
        this.ranges.set("changed", gr[1], rnow)
      } else {
        this.ranges.set("changed", rnow, gr[2])
      }
    } else {
      this.ranges.set("unchanged", 0, this.maxTime)
      this.ranges.set("changed", this.markerAt, rnow)
    }

    return this.setMarker()
  }

  getRange(type) {
    for (var i = 0; i < this.ranges.all.length; i++) {
      if(this.ranges.all[i][0] == type) return this.ranges.all[i]
    }
    return null
  }

  calculateCoordinates(radius) {
    // const coordinates = []
    // for (let angle_deg = 0; angle_deg < 360; angle_deg += 30) {
    //   let angle_rad = angle_deg * Math.PI / 180
    //   let x = radius * Math.cos(angle_rad)
    //   let y = radius * Math.sin(angle_rad)
    //   coordinates.push({ x: x, y: y })
    // }
    // return coordinates

    const coordinates = []
    const angleIncrement = Math.PI / 30 // 15 degrees in radians

    for (let minute = 1; minute <= 60; minute++) {
        const angle = angleIncrement * (minute - 15); // Starting from 3 o'clock
        const x = 0 + radius * Math.cos(angle)
        const y = 0 + radius * Math.sin(angle)
        coordinates.push({ x, y })
    }

    return coordinates
  }

  start() {
    if(this.timer) this.stop()
    this.render()
    this.timer = setInterval(_ => this.render(), 1000)
    this.setV("clockFace", el => el.offsetHeight)
    // setTimeout(_ => {
      this.setV("clockFace", el => { el.classList.add("running") })
    // }, 1)
    return this
  }

  stop() {
    clearInterval(this.timer)
    this.timer = null
    this.setV("clockFace", el => { el.classList.remove("running") })
  }

  msToMSstr(ms) {
    let secs = ms / 1000
    const mins = Math.floor(secs / 60)
    secs -= mins * 60
    return `${mins.toFixed(0).padStart(2, "0")}:${secs.toFixed(0).padStart(2, "0")}`
  }

  render() {
    const now = Date.now()
    const relative = now % this.maxTime
    const normalized = (now % (this.maxTime * 2)) / (this.maxTime * 2)
    // console.log(normalized.toFixed(2), relative / 1000)

    let gradient = []
    for (var i = 0; i < 2; i++) {
      this.ranges.all.forEach(([type, start, end], gi) => {
        const t1 = start / (this.maxTime * 2) + (i * 0.5)
        const t2 = end / (this.maxTime * 2) + (i * 0.5)
        let color = "gray"
        if(type == "changed") color = "#b0d8a4aa"
        if(type == "unknown") color = "#8281a0aa"
        if(type == "unchanged") color = "#e84258aa"

        gradient.push(`${color} ${t1}turn ${t2}turn`)
      })
    }

    this.setV("clockFace", el => {
      el.style.background = `conic-gradient(${gradient.join(", ")})`
    })

    this.setV("nowHandle", el => {
      el.style.setProperty('--rotate', `${-180 + 360 * normalized}deg`)
    })

    this.setV("markerHandle", el => {
      el.style.setProperty('--rotate', `${-180 + 180 * (this.markerAt / this.maxTime)}deg`)
    })

    const greenRange = this.getRange("changed")
    if(greenRange) {
      let [_, msBegin, msEnd] = greenRange

      this.setV("slot1", el => el.textContent = `${this.msToMSstr(msBegin)} – ${this.msToMSstr(msEnd)}`)
      this.setV("slot2", el => el.textContent = `${this.msToMSstr(msBegin + this.maxTime)} – ${this.msToMSstr(msEnd + this.maxTime)}`)
    }

    // const t = SotIngameTime.now()
    // // this.el.innerHTML = `Hi: ${SotIngameTime.toHumanString(t)} ${}`
    // this.setV("raw", JSON.stringify(t, null, 2))
    // this.setV("formatted", SotIngameTime.toHumanString(t))
    // this.setV("phase", t.phase)
    // this.setV("fishPhase", t.fishPhase)
    // this.setV("month", t.month)
    // this.setV("day", SotIngameTime.ordinalize(t.day))
    // this.setV("hour", SotIngameTime.pad(t.hour))
    // this.setV("minute", SotIngameTime.pad(t.minute))
    // this.setV("clockFace", el => {
    //   const deg = 360 * (1 - t.dayReal % 1)
    //   const ldeg = el.dataset.lastDeg ?? 0
    //   el.dataset.lastDeg = deg

    //   if(Math.abs(ldeg - deg) > 300) {
    //     // rolled over, skip animation
    //     el.classList.remove("running")
    //     el.style.setProperty('--rotate', `${deg}deg`)
    //     el.offsetHeight
    //     el.classList.add("running")
    //   } else {
    //     el.style.setProperty('--rotate', `${deg}deg`)
    //   }

    //   this.setV("clockHandles", ch => {
    //     ch.style.setProperty('--rotate', `${360 - deg}deg`)
    //   })
    //   this.setV("clockNotches", ch => {
    //     ch.style.setProperty('--rotate', `${360 - deg}deg`)
    //   })
    //   // if(el.dataset.lastDeg != deg) {

    //   // }
    //   // el.dataset.lastDeg = el.dataset.lastDeg ??
    //   // console.log(deg, parseFloat(el.style.getPropertyValue("--rotate")))
    // })
  }

  setV(k, v) {
    const el = this.el.querySelector(`[data-v="${k}"`)
    if(!el) return false
    if(typeof v == "function") {
      return v(el)
    } else if (el.innerHTML != v) {
      return el.innerHTML = v
    }
  }
}

class PartitionRange {
  constructor(opts = {}) {
    this.opts = Object.assign({}, { max: undefined }, opts)
    this.ranges = []
  }

  get all() { return this.ranges }

  set(type, start, end) {
    if(this.opts.max !== undefined && end < start) {
      this.set(type, start, this.opts.max)
      return this.set(type, 0, end)
    }

    let newRanges = []
    let newRange = [type, start, end]

    for (let i = 0; i < this.ranges.length; i++) {
      let [currentType, currentStart, currentEnd] = this.ranges[i]

      // If new range is completely before the current range
      if (end < currentStart) {
        newRanges.push(this.ranges[i])
        continue
      }

      // If new range is completely after the current range
      if (start > currentEnd) {
        newRanges.push(this.ranges[i])
        continue
      }

      // Overlapping cases
      if (currentType === type) {
        // Combine overlapping ranges of the same type
        newRange[1] = Math.min(newRange[1], currentStart)
        newRange[2] = Math.max(newRange[2], currentEnd)
      } else {
        // Shrink or split the existing range if it overlaps with a different type
        if (currentStart < start) {
          newRanges.push([currentType, currentStart, start - 1])
        }
        if (currentEnd > end) {
          newRanges.push([currentType, end + 1, currentEnd])
        }
      }
    }

    // Add the new or combined range
    newRanges.push(newRange)
    this.ranges = newRanges.sort((a, b) => a[1] - b[1])
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component, SotRefreshTimerElement }
