import { WorldLayer } from "../world_layer"
import { Debounce } from "../../../../appos/lib/debounce"
import { ExternalPromise } from "../../../../appos/lib/external_promise"

export class Islands extends WorldLayer {
  initPre() {
    this.dom = this.world.worldEl
  }

  init() {
    this.hasCanvas = false
    this.attachInitially = false
    this.enableInitially = true

    this.islands = []
    this.index = {}

    this.throttledResUpdate = new Debounce(600, _ => this._updateResolution())
    this.allIslandsHaveLoaded = new ExternalPromise()
    this.oneIslandEverInView = new ExternalPromise()
    //this.allIslandsHaveLoaded.then(_ => console.log("all loaded"))

    this.optreg.add("enum",  "renderer", "dynamic", { enum: ["dynamic", "dom", "canvas"] }).url(2).onChange(_ => { this.updateResolutionNow() })
    this.optreg.add("enum",  "resolution", "dynamic", { enum: ["dynamic", "low", "high"] }).url(2).onChange(_ => { this.updateResolutionNow() })
    this.optreg.add("bool", "shadows", true).onChange(v => { this.world.world.toggleClass("no-island-shadows", !v); this.canvasRenderer?.update() })

    this.initIslands()
  }

  get canvasRenderer() { return this.getLayer("IslandRender") }

  find(id) { return this.index[id] }

  initIslands() {
    this.visibilityObserver = new IntersectionObserver((events, obs) => {
      events.forEach(ev => {
        this.index[ev.target.dataset.id]?.setInView(ev.isIntersecting, ev.intersectionRatio >= 0.99)
      })
    }, { root: this.world.worldParentEl, threshold: [0, 0.99] })

    this.islandsPendingLoad = 0
    this.world.worldEl.querySelectorAll(".island").forEach(el => {
      const island = new Island(this, el.dataset.id, el)

      this.islandsPendingLoad += 1
      island.firstImageLoaded.then(_ => {
        this.islandsPendingLoad -= 1
        if(this.islandsPendingLoad == 0) setTimeout(this.allIslandsHaveLoaded.resolve, 0)
      })

      island.load()
      this.visibilityObserver.observe(el)

      this.islands.push(island)
      this.index[island.id] = island
    })
  }

  get all() {
    return this.islands
  }

  destroyIsland(island) {
    if(!this.index[island.id]) return
    const islidx = this.islands.indexOf(island)
    if(islidx !== -1) this.islands.splice(islidx, 1)
    delete this.index[island.id]
    island.destroy()
  }

  filter(filterFn) {
    return this.islands.filter(filterFn)
  }

  get inView() {
    return this.filter(isl => isl.inView)
  }

  get fullyInView() {
    const list = this.filter(isl => isl.fullyInView)
    if(!list.length) {
      const others = this.inView
      if(others.length == 1) return others
    }
    return list
  }

  get outOfView() {
    return this.filter(isl => !isl.inView)
  }

  _updateResolution() {
    const targetRes = this.oo("resolution").enumValue
    const targetRd = this.oo("renderer").enumValue

    this.islands.forEach(island => {
      let doHres = false

      if(targetRes == "dynamic") {
        doHres = this.world.realScale <= 125 && island.inView
      } else if (targetRes == "high") {
        doHres = island.inView
      }

      let useRenderer = targetRd
      if(this.world.realScale <= 350 && island.inView && targetRd == "dynamic") useRenderer = "canvas"
      if(useRenderer == "dynamic") useRenderer = "dom"
      island.setRenderer(useRenderer)
      island.loadResolution(doHres ? "high" : "low")
    })
  }
  updateResolution() { return this.throttledResUpdate.call() }
  updateResolutionNow() { return this.throttledResUpdate.cancel(true) }
}



export class Island {
  constructor(parent, id, el, startHigh = false) {
    this.parent = parent
    this.id = id
    this.el = el
    this.hasDropshadow = !this.el.classList.contains("no-shadow")
    this.inView = false
    this.fullyInView = false
    this.firstImageLoaded = new ExternalPromise()

    // parse data
    const d = this.el.dataset
    this.name = d.n
    this.renderer = "dom"
    this.removeImageBackground = d.rib == "true"
    this.rotation = parseFloat(d.r)
    this.cameraPosition = [parseFloat(d.cx), parseFloat(d.cy)]
    this.position = [parseFloat(d.x), parseFloat(d.y)]
    this.boundsRadius = parseFloat(d.br)
    this.triggerRadius = parseFloat(d.tr)
    this.safeRadius = parseFloat(d.sr)
    this.diveExclusionRadius = parseFloat(d.dr)
    this.maxRadius = Math.max(...[this.boundsRadius, this.triggerRadius, this.safeRadius, this.diveExclusionRadius].filter(x => !isNaN(x)))
    this.gridCoords = this.parent.pointToGridLocation(this.position)
    this.gridPos = [String.fromCharCode(64 + this.gridCoords[0]), this.gridCoords[1]]

    this.initTitle()
    this.initImages(d, startHigh)
    this.updateDimensions()
  }

  get world() { return this.parent.world }
  get activeResolution() { return this.resolutions.get(this.resolution) }
  get activeResolutionWidth() { return this.activeResolution?.w ?? this.boundsRadius }

  destroy() {
    this.el.remove()
    this.inView = false
    this.fullyInView = false
    this.world.layers.forEach(l => l.update())
  }

  co(k) { return this.parent.o(k) }
  load() { return this.loadResolution(this.resolution) }

  initTitle() {
    //this.el.title = `${this.name} (${this.id})\nr:${this.rotation}\ncx:${this.cameraPosition[0]} cy:${this.cameraPosition[1]}`
    // this.el.addEventListener("click", ev => {
    //   this.world.centerGameCoordinateInViewport(this.position, 3, { animate: true })
    // })
  }

  setRenderer(nRen) {
    if(nRen == this.renderer) return false

    // // teardown
    // if(this.renderer == "dom") {
    // } else if (this.renderer == "canvas") {
    // } else {
    //   console.warn("no teardown for renderer", nRen)
    // }

    // // setup
    // if(nRen == "dom") {
    // } else if (nRen == "canvas") {
    // } else {
    //   console.warn("no setup for renderer", nRen)
    // }
    delete this.alreadyLoadedResolution
    return this.renderer = nRen
  }

  initImages(d, startHigh = false) {
    this.resolution = startHigh ? "high" : "low"
    this.resolutions = new Map()
    if(d.i) this.resolutions.set("low", { w: parseFloat(d.w), src: d.i, img: this.createImage() })
    if(d.iz) this.resolutions.set("high", { w: parseFloat(d.wz ?? d.w), src: d.iz, img: this.createImage() })

    // fallback resolutions
    if(!this.resolutions.get("low")) {
      if(this.resolutions.get("high")) {
        console.log(this.id, "has no lowres image, using highres")
        this.resolutions.set("low", Object.assign({}, this.resolutions.get("high"), { img: this.createImage() }))
      } else {
        console.warn(this.id, "has no image")
      }
    } else if(!this.resolutions.get("high")) {
      // console.log(this.id, "has no highres, using lowres")
      this.resolutions.set("high", Object.assign({}, this.resolutions.get("low"), { img: this.createImage() }))
    }
  }

  loadResolution(res, silent = false) {
    if(res == this.alreadyLoadedResolution) return new Promise((resolve, reject) => { resolve() })
    this.alreadyLoadedResolution = res

    const resolution = this.resolutions.get(res)
    if(!resolution.promise) resolution.promise = new Promise((resolve, reject) => {
      resolution.img.addEventListener("load", ev => resolve(resolution), { once: true })
      resolution.img.addEventListener("error", e => reject(e), { once: true })

      if(this.removeImageBackground) {
        if(resolution.bgremovedSrc) {
          resolution.img.src = resolution.bgremovedSrc
        } else {
          this.removeBackgroundFromImage(resolution.src).then(v => resolution.img.src = resolution.bgremovedSrc = v)
        }
      } else {
        resolution.img.src = resolution.src
      }
    })

    if(silent) {
      return resolution.promise
    } else {
      return resolution.promise.then(ar => {
        this.resolution = res

        if(this.renderer == "dom") {
          this.resolutions.forEach((r, k) => {
            r.img.style.display = k == res ? "" : "none"
          })
          this.parent.canvasRenderer?.clearIslandFragment(this)
        } else if(this.renderer == "canvas") {
          this.resolutions.forEach((r, k) => {
            // console.log("resolve", res, k == res)
            r.img.style.display = "none"
          })
          this.parent.canvasRenderer?.renderIslandFragment(this)
        } else {
          console.warn("no trigger for renderer", this.renderer)
        }
        this.updateDimensions()
      })
    }
  }

  createImage() {
    const img = document.createElement('img')
    img.style.opacity = 0
    img.style.transform = `rotate(${this.rotation}deg)`

    img.addEventListener("load", ev => {
      ev.currentTarget.style.opacity = 1
      this.firstImageLoaded.resolve()
    }, { once: true })

    this.el.append(img)
    return img
  }

  centerInViewport(tscale = "auto", opts = {}) {
    if(tscale == "auto") {
      tscale = this.world.calculateScaleToFit(this.maxRadius * 2 * 1.1)
    } else if (!tscale) {
      tscale = this.world.vscale
    }
    this.world.centerGameCoordinateInViewport(this.position, tscale, opts)
  }

  setInView(iv, fiv, trigger = true) {
    this.inView = iv
    this.fullyInView = fiv
    if(iv) this.parent.oneIslandEverInView.resolve()
    // this.el.classList.toggle("invp", iv)
    // this.el.classList.toggle("finvp", fiv)
    if(trigger) this.parent.updateResolution()
    return this.inView
  }

  updateDimensions() {
    const [x, y] = this.world.translateToViewport(this.position)
    const w = this.activeResolutionWidth

    // fix for stupid tribute peak map
    if(this.id == "wld_feature_tribute_peak") {
      this.el.style.marginLeft = this.resolution == "high" ? "-4.5px" : ""
    }
    this.el.style.left = x + "px"
    this.el.style.top = y + "px"
    this.el.style.width = this.el.style.height = (w / this.world.scale) + "px"
  }

  async removeBackgroundFromImage(image) {
    const backgroundColor = { red: 255, green: 0, blue: 255 };
    const threshold = 10;
    const gthreshold = 100;
    let replaced = 0;

    const imageElement = new Image();
    imageElement.src = image;
    await new Promise(function(resolve) { imageElement.addEventListener('load', resolve); });

    var canvas = document.createElement('canvas');
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;

    var ctx = canvas.getContext('2d');
    ctx.drawImage(imageElement, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    for (var i = 0; i < imageData.data.length; i += 4) {
      const red = imageData.data[i];
      const green = imageData.data[i + 1];
      const blue = imageData.data[i + 2];
      if (Math.abs(red - backgroundColor.red) <= threshold &&
        (Math.abs(green - backgroundColor.green) <= threshold || Math.abs(green - backgroundColor.green) <= gthreshold) &&
        Math.abs(blue - backgroundColor.blue) <= threshold) {
        imageData.data[i + 3] = 0;
        replaced++;
      }
    }

    if(replaced > 10) {
      ctx.putImageData(imageData, 0, 0);
      return canvas.toDataURL(`image/png`);
    } else {
      return image
    }
  }
}
