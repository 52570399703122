import * as AppOS from "../appos"
import { Keybind } from "./keyboard/keybind"

const Component = class extends AppOS.Component {
  static name = "Keyboard"

  static DEFAULT_OPTIONS = {
    debug: false,
    as: "input",
    monitorModKeys: true,
    classModKeys: false,
  }

  init() {
    this.app[this.opts.as] = this
    this.html = document.querySelector("html")
    this.binds = new Map()
    this.modKeys = {
      held: 0,
      shift: false,
      ctrl: false,
      alt: false,
      meta: false,
    }
  }

  documentLoad() {
    this.resetPageEvents()

    document.addEventListener('keydown', ev => {
      this._updateModKeys(ev)
      for(const [bid, bind] of this.binds) {
        if(bind.handle(ev)) return false
      }
      this.pageEvents.down.forEach(h => h(ev))
    })

    document.addEventListener('keyup', ev => {
      this._updateModKeys(ev)
      for(const [bid, bind] of this.binds) {
        if(bind.handle(ev)) return false
      }
      this.pageEvents.up.forEach(h => h(ev))
    })

    window.addEventListener('resize', ev => {
      this.pageEvents.resize.forEach(h => h(ev))
    })
  }

  pageLoad() {
    this.resetPageEvents()
    this.clearPageBinds()
    this.html = document.querySelector("html")
  }

  resetPageEvents() {
    this.pageEvents = { down: [], up: [], resize: [] }
  }

  clearPageBinds() {
    this.binds.forEach(bind => {
      if(bind.scope != "document") bind.unbind()
    })
  }

  onKeydownThisPage(cb) {
    this.pageEvents.down.push(cb)
    return this
  }

  onKeyupThisPage(cb) {
    this.pageEvents.up.push(cb)
    return this
  }

  onResizeThisPage(cb) {
    this.pageEvents.resize.push(cb)
    return this
  }

  hotkey(key, opts = {}, fn) {
    if(typeof opts == "function" && !fn) [fn, opts] = [opts, {}];

    const fkey = Keybind.normalizeBindString(key)
    if(this.binds.get(fkey)) throw(`hotkey already exists with key ${fkey}`)

    const bind = new Keybind(fkey, opts, fn)
    bind.unbind = () => { this.binds.delete(fkey) }
    this.binds.set(fkey, bind)
    return bind
  }

  hotkeyThisPage(key, opts = {}, fn) {
    if(typeof opts == "function" && !fn) [fn, opts] = [opts, {}];
    opts = Object.assign({}, opts, { scope: "page" })
    return this.hotkey(key, opts, fn)
  }

  disableHotkeys(scopeOrOptOrFunc) { throw("@todo not implemented") }
  enableHotkeys(scopeOrOptOrFunc) { throw("@todo not implemented") }

  _updateModKeys(ev) {
    if(!this.opts.monitorModKeys) return
    this._updateModKey(ev, "shift")
    this._updateModKey(ev, "ctrl")
    this._updateModKey(ev, "alt")
    this._updateModKey(ev, "meta")
  }

  _updateModKey(ev, key) {
    if(ev[`${key}Key`] && !this.modKeys[key]) {
      this.modKeys.held += 1
      this.modKeys[key] = true
      if (this.opts.classModKeys) {
        this.html.classList.toggle(`kb-mod`, true)
        this.html.classList.toggle(`kb-mod-${key}`, true)
      }
    } else if(!ev[`${key}Key`] && this.modKeys[key]) {
      this.modKeys.held -= 1
      this.modKeys[key] = false
      if(this.opts.classModKeys) {
        this.html.classList.toggle(`kb-mod`, this.modKeys.held > 0)
        this.html.classList.toggle(`kb-mod-${key}`, false)
      }
    }
  }

  applyModKeys(ev) {
    if(ev.shiftKey) {
      this.modKeys.shift = ev.shiftKey
      this.modKeys.held += ev.shiftKey ? 1 : -1
    }

    if(ev.ctrlKey) {
      this.modKeys.ctrl = ev.ctrlKey
      this.modKeys.held += ev.ctrlKey ? 1 : -1
    }

    if(ev.metaKey) {
      this.modKeys.meta = ev.metaKey
      this.modKeys.held += ev.metaKey ? 1 : -1
    }

    if(ev.altKey) {
      this.modKeys.alt = ev.altKey
      this.modKeys.held += ev.altKey ? 1 : -1
    }

    return res
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }

// # global keydown handler for this page only (also onKeyupThisPage)
// <template appos-page-load><script>
//   env.app.input?.onKeydownThisPage(ev => {
//     if(ev.keyCode == 70 && (ev.metaKey || ev.ctrlKey) && ev.altKey) {
//       $("#q_n").focus().select()
//       ev.preventDefault()
//     }
//   })
// </script></template>

// # hotkeys (trigger, { global = works in inputs }?, fn?)
// env.app.input.hotkey("Control+KeyS", ev => { ev.preventDefault(); this.save() })
// env.app.input.hotkey("Control+KeyR").global().do(ev => { ev.preventDefault(); this.doSomething() })
// env.app.input.hotkeyThisPage("Control+KeyP", { global: true }, ev => { ev.preventDefault(); this.doSomething() })


// ====================
// = TODO/BRAIN @todo =
// ====================

// KeyClass (using registry)
// kb.key("p", downFor: [3000, key => { console.log("p down hold", key) }], down: key => { console.log("p down", key) }, up: key => { console.log("p up", key) }, press: key => { console.log("p press", key) })
// kb.keyThisPage("l").downFor(3000, key => { console.log("l down hold", key) }).down(key => { console.log("l down", key) }).up(key => { console.log("l up", key) }).press(key => { console.log("l press", key) })
// kb.hotkey("action+k > action+o").do(_ => {})
// kb.hotstring("yomama", key => { console.log("hotstring", key) })
// kb.hotstring("goto", key => { console.log("hotstring", key) })
// kb.hotstring("yopapa", { global: true }, key => { console.log("hotstring", key) })

// Scopes? (enable/disable per scope)

// Registry
// kb.keys.pressed() // currently pressed keys in order of activation
// kb.keys.isPressed() // is key currently pressed
// kb.keys.pressedFor() // how long is key down for (-1 if not pressed)
// kb.keys.downSince() // since when key is down (null if not pressed)
// kb.inputBuffer(maxLength).clear().append(text).endsWith()
