import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Bootstrap.Tooltips"

  init() {
    $(document).on("appos:tooltip:init", (ev, x) => {
      this.initTTon(x ?? ev.detail)
    })
  }

  pageLoad() {
    this.initTTon()
  }

  initTTon(parent) {
    parent ??= document

    parent.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
      bootstrap.Tooltip.getOrCreateInstance(el)
    })
    parent.querySelectorAll('.help[title]').forEach(el => {
      bootstrap.Tooltip.getOrCreateInstance(el)
    })
    parent.querySelectorAll('.delayed-help[title]').forEach(el => {
      bootstrap.Tooltip.getOrCreateInstance(el, { delay: { show: 500, hide: 75 } })
    })
    parent.querySelectorAll('abbr[title]').forEach(el => {
      bootstrap.Tooltip.getOrCreateInstance(el, { delay: { show: 1250, hide: 400 } })
    })
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component }
