import * as AppOS from "../../appos"
import Panzoom from '@panzoom/panzoom'

import { Performance } from "./performance"
import { AssetLoader } from "./asset_loader"
import { UrlParamManager } from "./url_param_manager"
import { WorldLayerOptreg, WorldLayerMath } from "./world_layer"
import { WorldCursor } from "./world_cursor"
import { Animation } from "./animation"

import { UI } from "./layers/ui"
import { Islands } from "./layers/islands"
import { IslandRender } from "./layers/island_render"
import { IslandNames } from "./layers/island_names"
import { IslandCircles } from "./layers/island_circles"
import { IslandLocations } from "./layers/island_locations"
import { GridBackground } from "./layers/grid_background"
import { GridForeground } from "./layers/grid_foreground"
import { MeasureTool } from "./layers/measure_tool"
import { Searocks } from "./layers/searocks"
import { Storm } from "./layers/storm"
import { Network } from "./layers/network"

// TopMargin 2300.0
// LeftMargin  2800.0

// MinZoom 650.0
// InitialZoom 10000.0

// translate url x/y to center x/y (so that a URL shows the same center)

// custom shapes (for-dev) then map-markers (circles, route, lines, arrows, curves?, draw?)
//    - create as entities
//    - save to localStorage/remoteWebsocket
// redo measure tool (multi-point, ui)
// gotoAnything (t => launchy (islands, storm, landmarks?))
// general opts UI
//   - toggle URL-sync
//   - save&restore canonical settings from localstorage
//   - <uiOnly-ish> toggle pan/zoom
//   - <uiOnly> canonical-ify URL
//   - <uiOnly> cycle image-quality
//   - <uiOnly> toggle coords
// island names
//   - text size (make bigger and hide smaller islands when zoomed out very far)
// storm sim
//   - centerOnStorm/followStorm
//   - activeMode (or rather inactive, make it faded and monochrome) // artistic rendition
//   - segment length
//   - radius heatmap
// gate features, map and lobby behind password (modal)
// synced canvas?

// optim: distinguish between zoom and pan events, only resize text when needed, etc

// rock sprites? (small, medium, large, gate)
// update textures (some are out of date)

export class World extends AppOS.mergeClassesAsModule(WorldLayerOptreg, WorldLayerMath) {
  constructor(world) {
    super()
    this.world = world
    this.worldEl = world.get(0)
    this.worldParentEl = this.worldEl.parentElement

    this.xZero   = Math.abs(parseFloat(this.world.data("xmin")))
    this.yZero   = Math.abs(parseFloat(this.world.data("ymin")))
    this.width   = this.xZero + parseFloat(this.world.data("xmax"))
    this.height  = this.yZero + parseFloat(this.world.data("ymax"))
    this.scale   = parseFloat(this.world.data("scale"))
    this.regions = new Map()
    this.world.data("regions").forEach(rd => this.regions.set(rd.id, rd))
    this.paddingX = 0 //23000.0 * 2 //parseFloat(this.world.data("padding")) //+ 500000
    this.paddingY = 0 //28000.0 * 2 //parseFloat(this.world.data("padding")) //+ 500000
    this.lastX = this.lastY = 0

    this.layers = new Map()
    this.allLayersReady = false
    this.url = new UrlParamManager(this)
    this.performance = new Performance()

    this.setupOptreg()
    this.optreg.add("float", "x", 0, { precision: 3 }).ui(0).url(2)
    this.optreg.add("float", "y", 0, { precision: 3 }).ui(0).url(2)
    this.optreg.add("float", "r", 1, { precision: 3 }).ui(0).url(2)
    this.optreg.add("str", "l", "").ui(0).url(0)
    this.optreg.add("bool", "renderAsync", false).ui(0).onChange(v => {
      this.layers.forEach(l => {
        if(l.mayRenderAsync) { l.debounceRenderAsync = v ? 0 : null }
      })
    })

    this.optreg.cmd("!center", async (cmd, what) => {
      setTimeout(_ => {
        if(what == "map") {
          this.centerMap()
        } else {
          this.islands.find(what)?.centerInViewport()
        }
      })
    })

    this.assets = new AssetLoader(this)
    this.assets.on("assetLoadFailed", (asset, error) => {
      this.toastNotification(`Failed to load asset '${asset.key}': ${error.status ?? ""} ${error.statusText ?? ""} ${error.message ?? ""}`)
    })

    this.updateWorldDimensions()
    this.setupPanzoomWithListeners()
    this.cursor = new WorldCursor(this).apply()

    this.layers.set("UI", new UI(this))
    this.layers.set("Islands", new Islands(this))
    this.layers.set("IslandRender", new IslandRender(this))
    this.layers.set("GridBackground", new GridBackground(this))
    this.layers.set("IslandCircles", new IslandCircles(this))
    this.layers.set("Searocks", new Searocks(this))
    this.layers.set("IslandLocations", new IslandLocations(this))
    this.layers.set("Storm", new Storm(this))
    this.layers.set("MeasureTool", new MeasureTool(this))
    this.layers.set("IslandNames", new IslandNames(this))
    this.layers.set("GridForeground", new GridForeground(this))
    if(this.world.data("net")) this.layers.set("Network", new Network(this))

    this.layers.forEach(l => l.startup())
    this.layers.forEach(l => l.ready())
    this.performance.setup(this, this.world.data("performance"))

    this.allLayersReady = true
    this.oo("l").url(2)
  }

  layer(l) { return this.layers.get(l) }
  get ui() { return this.layer("UI") }
  get islands() { return this.layer("Islands") }
  get network() { return this.layer("Network") }

  toastNotification(...args) { return this.ui.toastNotification(...args) }

  restoreFromCanonicalUrl() {
    this.originalParams ??= this.url.getHashParams()
    this.url.restoreFromCanonicalUrl()
    return this
  }

  layerEnabled(layer) { this.updateLayerConfig() }
  layerDisabled(layer) { this.updateLayerConfig() }
  updateLayerConfig() {
    const opt = this.oo("l")
    if(opt.getSync("url") < 2) return false
    this.oo("l").value = this.url.buildLayerConfig().join(",")
  }

  setupPanzoomWithListeners() {
    this.panzoom = Panzoom(this.worldEl, {
      maxScale: this.scale * 0.5,
      minScale: this.scale * 0.00075,
      animate: false,
      // startScale: 1.5,
      handleStartEvent: (ev) => { ev.preventDefault() },
    })
    this.setupPanzoomAnimation()
    this.updateCaches()

    this.worldEl.addEventListener('panzoomstart', ev => this.handlePanzoomstart(ev))
    this.worldEl.addEventListener('panzoomchange', ev => this.handlePanzoomchange(ev))
    this.worldEl.addEventListener('panzoompan', ev => this.handlePanzoompan(ev))
    this.worldEl.addEventListener('panzoomzoom', ev => this.handlePanzoomzoom(ev))
    this.worldEl.addEventListener('panzoomend', ev => this.handlePanzoomend(ev))
    this.worldParentEl.addEventListener('wheel', ev => this.handleWheel(ev))
    this.worldParentEl.addEventListener('pointerdown', ev => this.handlePointerDown(ev))
    this.worldParentEl.addEventListener('mousemove', ev => this.handleMouseMove(ev))
    this.worldParentEl.addEventListener('pointerup', ev => this.handlePointerUp(ev))
    this.worldParentEl.addEventListener('contextmenu', ev => this.handleContextmenu(ev))
    // this.worldParentEl.addEventListener('click', ev => this.handleClick(ev))

    this.resizeObserver = new ResizeObserver((e, o) => this.handleResize(e, o))
    this.resizeObserver.observe(this.worldParentEl)
  }

  setupPanzoomAnimation() {
    this.panzoomAnimation = new Animation(12000, {
      easing: "easeInOutCubic",
    },(a, t, rt, et, now) => {
      a.vscale = a.wasZ + (t * a.zoomDelta)
      a.vpan = {
        x: a.wasX + (t * a.panDelta[0]),
        y: a.wasY + (t * a.panDelta[1]),
      }
      this.worldEl.style.transform = `scale(${a.vscale.toFixed(3)}) translate(${a.vpan.x.toFixed(3)}px, ${a.vpan.y.toFixed(3)}px)`
      this.handlePanzoomchange()
    }).on("start", a => {
      a.wasX = this._vpan.x
      a.wasY = this._vpan.y
      a.wasZ = this._vscale
      this.panzoom.setOptions({ disablePan: true, disableZoom: true })
    }).on("ended", a => {
      delete a.vscale
      delete a.vpan
      this.panzoom.setOptions({ disablePan: false, disableZoom: false })
      this.panzoom.zoom(a.targetZoom)
      this.panzoom.pan(...a.targetPan)
    }).define("run", (a, ms = 1000, zoom, pan) => {
      a.duration = ms
      a.targetZoom = zoom
      a.targetPan = pan
      a.zoomDelta = zoom - this._vscale
      a.panDelta = [pan[0] - this._vpan.x, pan[1] - this._vpan.y]
      a.start()
    })
  }



  // =========================
  // = Scale, Vscale, Caches =
  // =========================
  get vscale() { return this._vscale ?? this.panzoom.getScale() }
  get realScale() { return this.scale / this.vscale }

  updateWorldDimensions() {
    this.worldEl.style.width = `${(this.width + this.paddingX * 2) / this.scale}px`
    this.worldEl.style.height = `${(this.height + this.paddingY * 2) / this.scale}px`
  }

  updateCaches() {
    this.worldRect = this.worldEl.getBoundingClientRect()
    this.worldParentRect = this.worldParentEl.getBoundingClientRect()
    this._vpan = this.panzoomAnimation?.vpan ?? this.panzoom.getPan()
    this._vscale = this.panzoomAnimation?.vscale ?? this.panzoom.getScale()
    this.oo("r").value = this._vscale
    this.oo("x").value = this._vpan.x
    this.oo("y").value = this._vpan.y

    const rscale = this.realScale
    const vvr = {
      topleft: this.translateViewportToReal([0, 0]),
      width: this.worldParentRect.width * rscale,
      height: this.worldParentRect.height * rscale,
    }
    vvr.bottomright = [vvr.topleft[0] + vvr.width, vvr.topleft[1] + vvr.height]
    this.viewportVirtualRect = vvr
  }



  // ====================
  // = Translation Math =
  // ====================
  translateToReal(p) { return [this.translateToRealX(p[0]), this.translateToRealY(p[1])] }
  translateToRealX(x) { return (x * this.scale) - this.xZero - this.paddingX }
  translateToRealY(y) { return (y * this.scale) - this.yZero - this.paddingY }
  translateToViewport(p) { return [this.translateToViewportX(p[0]), this.translateToViewportY(p[1])] }
  translateToViewportX(x) { return (x + this.xZero + this.paddingX) / this.scale }
  translateToViewportY(y) { return (y + this.yZero + this.paddingY) / this.scale }
  translateGameToViewportBox(b) { return [this.translateGameToViewportX(b[0]), this.translateGameToViewportY(b[1]), b[2] / this.realScale, b[3] / this.realScale] }
  translateGameToViewport(p) { return [this.translateGameToViewportX(p[0]), this.translateGameToViewportY(p[1])] }
  translateGameToViewportX(x) { return ((x + this.xZero + this.paddingX) / this.realScale ) + (this.worldRect.left - this.worldParentRect.left) }
  translateGameToViewportY(y) { return ((y + this.yZero + this.paddingY) / this.realScale ) + (this.worldRect.top - this.worldParentRect.top) }
  translateGameRadiusToViewport(rad) { return rad / this.realScale }

  translateClientToReal(p) {
    const vscale = this.vscale
    return [
      this.translateToRealX((p[0] - this.worldRect.left) / vscale),
      this.translateToRealY((p[1] - this.worldRect.top) / vscale),
    ]
  }

  translateViewportToReal(p) {
    const vscale = this.vscale
    return [
      this.translateToRealX((p[0] - (this.worldRect.left - this.worldParentRect.left)) / vscale),
      this.translateToRealY((p[1] - (this.worldRect.top - this.worldParentRect.top)) / vscale),
    ]
  }

  mapCenterOffsets(targetScale) {
    targetScale ??= this.vscale
    return [
      (this.worldParentRect.width - ((this.worldRect.width) / this.vscale)) / targetScale / 2,
      (this.worldParentRect.height - ((this.worldRect.height) / this.vscale)) / targetScale / 2,
    ]
  }

  translateGameToCenterOffset(gameCoord, targetScale) {
    targetScale ??= this.vscale

    // translate game to absolute viewport in target scale
    const tp = [
      (gameCoord[0] + this.xZero + this.paddingX) / (this.scale / targetScale),
      (gameCoord[1] + this.yZero + this.paddingY) / (this.scale / targetScale),
    ]

    // calculate world dimensions/center in target scale
    const worldWidth = (this.worldRect.width / this.vscale) * targetScale
    const worldHeight = (this.worldRect.height / this.vscale) * targetScale
    const worldCenterX = worldWidth / 2
    const worldCenterY = worldHeight / 2

    // shift world center to target and apply center offsets
    const [xo, yo] = this.mapCenterOffsets(targetScale)
    const worldCenterDeltaX = xo - (-worldCenterX + tp[0]) / targetScale
    const worldCenterDeltaY = yo - (-worldCenterY + tp[1]) / targetScale

    return [worldCenterDeltaX, worldCenterDeltaY]
  }

  centerMap(targetScale, opts = {}) {
    if(opts.animate) {
      this.panzoomAnimation.run(opts.duration ?? 1000, targetScale, this.mapCenterOffsets(targetScale))
    } else {
      if(targetScale && targetScale != this.vscale) this.panzoom.zoom(targetScale)
      this.panzoom.pan(...this.mapCenterOffsets(targetScale))
    }
  }

  centerGameCoordinateInViewport(gameCoord, targetScale, opts = {}) {
    if(opts.animate) {
      this.panzoomAnimation.run(opts.duration ?? 1000, targetScale, this.translateGameToCenterOffset(gameCoord, targetScale))
    } else {
      if(targetScale && targetScale != this.vscale) this.panzoom.zoom(targetScale)
      this.panzoom.pan(...this.translateGameToCenterOffset(gameCoord, targetScale))
    }
  }

  calculateScaleToFit(width, height = width) {
    const translatedWidth = width / this.scale
    const translatedHeight = height / this.scale
    const scaleWidth = this.worldParentRect.width / translatedWidth
    const scaleHeight = this.worldParentRect.height / translatedHeight
    return Math.min(scaleWidth, scaleHeight)
  }

  getRegionFromPoint(x, y) {
    if(y == null) [x, y] = x;

    for (const [rid, rd] of this.regions) {
      if(this.pointIntersectCircle([x, y], rd.center, rd.radius)) {
        return rd
      }
    }

    return null // no region found
  }

  pointToGridLocation(x, y, asChar) {
    if(y === true || y === false) [asChar, y] = [y, null];
    if(y == null) [x, y] = x;

    const gx = Math.floor((x + this.xZero) / (this.width / 26)) + 1
    const gy = Math.floor((y + this.yZero) / (this.height / 26)) + 1

    if(asChar) {
      return [
        gx <= 0 || gx > 26 ? `☠${gx <= 0 ? gx : gx - 26}` : String.fromCharCode(64 + gx),
        gy,
      ]
    } else {
      return [gx, gy]
    }
  }



  // ===============
  // = Contextmenu =
  // ===============
  openContextmenu(cmenu, ev) {
    this.activeContextMenu?.close()
    cmenu.opts.onDestroyManager = i => {
      if(this.activeContextMenu == i) delete this.activeContextMenu
    }
    cmenu.open(ev)
    this.activeContextMenu = cmenu
  }



  // ==================
  // = Event handling =
  // ==================
  layerCancelableEvent(ev, handler, ...args) {
    const h = `handleParent${handler}`
    if(this.cursor[h]?.(ev, ...args) === false) return true
    for (const [k, l] of this.layers) {
      if(l[h] && l[h](ev, ...args) === false) return true
    }
    return false
  }

  handlePanzoomstart(ev) {
    this.panzooming = true
    this.cursor.startGrab()
  }

  handlePanzoomend(ev) {
    this.panzooming = false
    this.cursor.endGrab()
  }

  handlePanzoompan(ev) {
    this.layers.forEach((l, k) => l.updatePan?.(ev))
  }

  handlePanzoomzoom(ev) {
    this.layers.forEach((l, k) => l.updateZoom?.(ev))
  }

  handlePanzoomchange(ev) {
    if(this.panzooming && ev?.detail?.originalEvent?.clientX !== undefined) {
      this.lastPointerPanMove = [ev.detail.originalEvent.clientX, ev.detail.originalEvent.clientY]
      this.cursor.updateGrab()
    }
    this.updateCaches()
    if(ev) this.ui.updateHudCoords(ev.detail.originalEvent)
    this.islands.updateResolution()
    // if(ev.detail.originalEvent) this.url.updateLiveLater()
    this.layers.forEach((l, k) => l.update?.(ev))
  }

  handleResize(e, o) {
    this.updateCaches()
    this.layers.forEach((l, k) => l.handleParentResize?.(e, o))
  }

  handleWheel(ev) {
    this.wheelJustOccurred = true
    if(this.wheelJustOccurredTimer) clearTimeout(this.wheelJustOccurredTimer)
    this.wheelJustOccurredTimer = setTimeout(_ => {
      this.wheelJustOccurred = false
    }, 0)
    this.panzoom.zoomWithWheel(ev)
    // this.ui.updateHudCoords(ev)
    // setTimeout(_ => this.ui.updateHudCoords(ev), 1)
  }

  handlePointerDown(ev) {
    if(this.activeContextMenu && !ev.target.closest(".contextmenu")) this.activeContextMenu.close()
    if($(ev.target).closest(`[data-is="hud"]`).length) return undefined
    if($(ev.target).closest(`[data-is="modal"]`).length) return undefined

    this.lastPointerDown = [ev.clientX, ev.clientY]
    if(this.layerCancelableEvent(ev, "PointerDown")) return undefined
  }

  handleMouseMove(ev) {
    if(this.layerCancelableEvent(ev, "MouseMove")) return undefined
    this.ui.updateHudCoords(ev)
  }

  handleContextmenu(ev) {
    // we already capture this with pointerUp which also fires before contextmenu
    // but preventDefault has no effect in pointerUp (at least not in all browsers)
    if(document.cancelNextContextmenu) {
      delete document.cancelNextContextmenu
      ev.preventDefault()
    }
  }

  handlePointerUp(ev) {
    if($(ev.target).closest(`[data-is="hud"]`).length) return undefined
    if($(ev.target).closest(`[data-is="modal"]`).length) return undefined

    if(this.layerCancelableEvent(ev, "PointerUp")) return undefined
    if(this.mouseDeltaThresholdAny(1)) {
      if(this.layerCancelableEvent(ev, "DragClick", this.mouseMoveDelta)) return undefined
    } else {
      if(this.layerCancelableEvent(ev, "Click")) return undefined
    }
    delete this.lastPointerDown
    delete this.lastPointerPanMove
  }

  get mouseMoveDelta() {
    if(!this.lastPointerDown) return [null, null]
    if(!this.lastPointerPanMove) return [0, 0]
    return [
      Math.abs(this.lastPointerPanMove[0] - this.lastPointerDown[0]),
      Math.abs(this.lastPointerPanMove[1] - this.lastPointerDown[1]),
    ]
  }

  mouseDeltaThresholdAny(limit = 1) {
    const moveDelta = this.mouseMoveDelta
    return moveDelta[0] > limit || moveDelta[1] > limit
  }

  handleKeydown(ev) {
    if(this.layerCancelableEvent(ev, "Keydown")) return undefined

    if (!(ev.shiftKey || ev.altKey || ev.metaKey || ev.ctrlKey)) {
      if (ev.key == "c") {
        $(`[data-hudctn="controls"]`).toggle()
      } else if (ev.key == "r") {
        this.panzoom.reset({ animate: false })
      } else if (ev.key == "o") {
        this.layers.get("IslandCircles").toggle()
      } else if (ev.key == "l") {
        this.layers.get("IslandLocations").toggle()
      } else if (ev.key == "s") {
        this.layers.get("Storm").toggle()
      } else if (ev.key == "p") {
        this.ui.oo("coordsEnabled").toggleValue()
      } else if (ev.key == "m") {
        this.layers.get("MeasureTool").toggle()
      } else if (ev.key == "g") {
        this.layers.get("GridBackground").toggle()
      } else if (ev.key == ".") {
        const obj = this.islands.oo("resolution")
        obj.toggleValue()
        this.ui.toastNotification(`Using${obj.isDefault() ? ` default` : ``} resolution: ${obj.formattedValue}`)
      } else if (ev.key == ",") {
        this.ui.oo("outer").toggleValue()
      } else {
        console.log(ev.key, ev.code)
      }
    } else if (!(ev.altKey || ev.metaKey || ev.ctrlKey)) {
      if (ev.key == "S") {
        this.layers.get("Searocks").toggle()
      } else if (ev.key == "R") {
        this.url.restoreFromCanonicalParams(this.originalParams)
      } else if (ev.key == "C") {
        this.capitalCDown ??= -1
        this.capitalCDown += 1
        if(this.capitalCDown > 0) return

        const threshold = 1000
        if(this.doubleCapitalCWindow && this.doubleCapitalCWindow - performance.now() > 0) {
          this.url.removeCanonical()
          delete this.doubleCapitalCWindow
        } else {
          this.url.updateCanonical()
          this.doubleCapitalCWindow = performance.now() + threshold
        }
      } else if(ev.key == ":") {
        const obj = this.islands.oo("renderer")
        obj.toggleValue()
        this.ui.toastNotification(`Using${obj.isDefault() ? ` default` : ``} renderer: ${obj.formattedValue}`)
      }
    }
  }

  handleKeyup(ev) {
    if(this.layerCancelableEvent(ev, "Keyup")) return undefined

    if(this.performance?.enabled && ev.code == "NumpadDivide") {
      return setTimeout(_ => {
        ev.shiftKey ? this.performance.resetMinMax() : this.performance.reset()
      }, 1)
    }

    if (!(ev.shiftKey || ev.altKey || ev.metaKey || ev.ctrlKey)) {
    } else if (!(ev.altKey || ev.metaKey || ev.ctrlKey)) {
      if (ev.key == "C") {
        delete this.capitalCDown
      }
    }
  }
}
