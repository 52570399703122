import { mergeClassesAsModule as Modules } from "../../appos"
import { TransientMap as Tmap } from "../../lib/transient_map"
import { OptionRegistry } from "./option_registry"
import { CanvasTextElement } from "./canvas_text_element"
import { Easings } from "./animation"




export class WorldLayerOptreg {
  setupOptreg(opts = {}) {
    const target = opts.target ?? this

    this.optreg = new OptionRegistry({
      namespace: opts.namespace,
      defaultSync: opts.defaultSync ?? { ui: 1, url: 1, net: 1 },
    })

    this.optreg.onValueChange((opt, newValue, oldValue, obj) => {
      const urlSync = obj.getSync("url")
      if(urlSync >= 2) {
        const okv = []
        obj.serializeUrlParam(okv)
        let ok = okv.length ? okv[0][0] : obj.fullKey
        let ov = okv.length ? okv[0][1] : undefined
        if(urlSync >= 3) {
          target.url.updateNow(ok, ov)
        } else {
          target.url.updateLater(ok, ov)
        }
      }

      if(obj.getSync("ui") >= 1) {
        this.ui?.updateOption?.(this, opt, newValue, oldValue, obj)
      }
    })
  }
  o(...args) { return this.optreg.fetch(...args) }
  oo(...args) { return this.optreg.get(...args) }
  co(...args) { return (this.parent ?? this).optreg.fetch(...args) }
  coo(...args) { return (this.parent ?? this).optreg.get(...args) }
}



export class WorldLayerUI {
  show() { this.dom.classList.remove("d-none"); return this }
  hide() { this.dom.classList.add("d-none"); return this }
  HUDref() { return $(`[data-hudctn="${this.constructor.name}"]`) }
  showHUD() { this.HUDref().show() }
  hideHUD() { this.HUDref().hide() }
  updateLayerHUDToggle() {
    $(`.ui-toggle[data-layer-toggle="${this.constructor.name}"]`).each((i, el) => {
      el.classList.toggle("layer-active")
      $(el).toggleClass(el.dataset.activeClass)
    })
  }
  enableInteractions() { this.dom.classList.remove("clickthru"); return this }
  disableInteractions() { this.dom.classList.add("clickthru"); return this }
  toastNotification(...args) { return this.ui.toastNotification(...args) }

  attach() {
    this.attachTo[this.mode == "fg" ? "append" : "prepend"](this.dom)
    this.adjustCanvas()
    return this
  }

  createDom() {
    if(this.dom) return false
    this.dom = document.createElement("div")
    this.dom.classList.add("canvas-layer", "d-none", "clickthru")
    this.dom.setAttribute("layer-id", this.constructor.name)
  }
}



export class WorldLayerMath {
  clamp(value, lower, upper) {
    if(lower != null && value < lower) return lower
    if(upper != null && value < upper) return upper
    return value
  }

  rclamp(value, lower = 0, upper = 1) {
    return this.clamp(value, lower, upper)
  }

  zoomAlpha(startAt = 1.5, endAt = 3, easing = "linear", clampLower = true, clampUpper = clampLower) {
    let alpha = 0.0
    let clampedScale = Math.max(1, this.world.vscale)

    if(clampedScale > startAt) {
      alpha = (clampedScale - startAt) / (endAt - startAt)
      if(clampLower && alpha < 0) alpha = 0
      if(clampUpper && alpha > 1) alpha = 1
      alpha = Easings[easing](alpha)
    }

    return alpha
  }

  zoomVec(vec, ...args) { return vec + (vec * this.zoomAlpha(...args)) }

  distance2d(v1, v2) {
    const dX = v2[0] - v1[0]
    const dY = v2[1] - v1[1]
    return Math.sqrt(dX * dX + dY * dY)
  }

  distance2dSquared(v1, v2) {
    const dX = v2[0] - v1[0]
    const dY = v2[1] - v1[1]
    return dX * dX + dY * dY
  }

  generateRandomArray(N, x, y) {
    if (x > y) { [x, y] = [y, x] }

    const array = []
    for (let i = 0; i < N; i++) {
      const randomNum = Math.random() * (y - x) + x
      array.push(randomNum)
    }
    return array
  }

  prepareRandomDashLayout(...args) {
    this.lineDashLayout = this.generateRandomDashLayout(...args)
  }

  generateRandomDashLayout(num = 20, lower = 11, upper = 18, lower2, upper2) {
    const lay = { seq: null, len: 0 }

    if(lower2 && upper2) {
      const a1 = this.generateRandomArray(num / 2, lower, upper)
      const a2 = this.generateRandomArray(num / 2, lower2, upper2)
      const r = []
      while(a1.length || a2.length) {
        if(a1.length) r.push(a1.shift())
        if(a2.length) r.push(a2.shift())
      }
      lay.seq = r
    } else {
      lay.seq = this.generateRandomArray(num, lower, upper)
    }
    lay.seq.forEach(n => lay.len += n)
    return lay
  }

  translateOnPlane(p, dim, xf = -0.5, yf = xf) {
    const np = [p[0], p[1]]
    np[0] += dim[0] * xf
    np[1] += dim[1] * yf
    return np
  }

  lerpSize(min, max, slower = 1, supper = 50) {
    const vscale = Math.max(slower, Math.min(this.world.vscale, supper))
    let size = min + (max - min) * (vscale - slower) / (supper - slower)
    return Math.max(min, Math.min(size, max))
  }

  lerpDim(dim, rat = 0.25, ...args) {
    return [
      this.lerpSize(dim[0] * rat, dim[0], ...args),
      this.lerpSize(dim[1] * rat, dim[1], ...args),
    ]
  }

  pointIntersectBox(p, box) {
    return p[0] >= box[0] && p[0] <= (box[0] + box[2]) && p[1] >= box[1] && p[1] <= (box[1] + box[3])
  }

  pointIntersectCircle(p, cp, cr) {
    return this.distance2dSquared(p, cp) <= cr ** 2
  }

  pointIntersectViewport(p) {
    return this.pointIntersectBox(p, [...this.viewportRect.topleft, this.viewportRect.width, this.viewportRect.height])
  }

  doBoxesIntersect(a1, a2, b1, b2) {
    // return maxAx >= minBx && minAx <= maxBx && minAy <= maxBy && maxAy >= minBy
    return Math.max(a1[0], a2[0]) >= Math.min(b1[0], b2[0]) && Math.min(a1[0], a2[0]) <= Math.max(b1[0], b2[0]) && Math.min(a1[1], a2[1]) <= Math.max(b1[1], b2[1]) && Math.max(a1[1], a2[1]) >= Math.min(b1[1], b2[1])
  }

  padBox(box, ...padding) {
    let pad = { top: 0, right: 0, bottom: 0, left: 0 }
    if(padding.length == 1 && padding[0] instanceof Object) {
      pad = padding[0]
    } else if (padding.length == 1) {
      padding.top = padding.right = padding.bottom = padding.left = padding[0]
    } else if (padding.length == 2) {
      padding.top = padding.bottom = padding[0]
      padding.right = padding.left = padding[1]
    } else if (padding.length == 3) {
      padding.top = padding[0]
      padding.right = padding.left = padding[1]
      padding.bottom = padding[2]
    } else if (padding.length == 4) {
      padding.top = padding[0]
      padding.right = padding[1]
      padding.bottom = padding[2]
      padding.left = padding[3]
    }

    return [
      box[0] - padding.left,
      box[1] - padding.top,
      box[2] + padding.left + padding.right,
      box[3] + padding.top + padding.bottom,
    ]
  }

  getLineSlope(p1, p2) {
    return (p2[1] - p1[1]) / (p2[0] - p1[0])
  }

  getAngleBetweenPoints(p1, p2) {
    const deltaY = p2[1] - p1[1]
    const deltaX = p2[0] - p1[0]

    // Use atan2 to get the angle in radians, and convert it to degrees
    const angleRadians = Math.atan2(deltaY, deltaX)
    const angleDegrees = angleRadians * 180 / Math.PI

    // Ensure the angle is positive (between 0 and 360 degrees)
    const positiveAngle = (angleDegrees + 360) % 360

    return positiveAngle
  }

  rotateLineAroundCenter(p1, p2, degrees) {
    const angle = degrees * (Math.PI / 180)
    // Calculate the center point of the line
    const centerX = (p1[0] + p2[0]) / 2
    const centerY = (p1[1] + p2[1]) / 2

    // Translate points to origin
    const x1Translated = p1[0] - centerX
    const y1Translated = p1[1] - centerY
    const x2Translated = p2[0] - centerX
    const y2Translated = p2[1] - centerY

    // Perform rotation
    const cosAngle = Math.cos(angle)
    const sinAngle = Math.sin(angle)

    const x1Rotated = x1Translated * cosAngle - y1Translated * sinAngle
    const y1Rotated = x1Translated * sinAngle + y1Translated * cosAngle

    const x2Rotated = x2Translated * cosAngle - y2Translated * sinAngle
    const y2Rotated = x2Translated * sinAngle + y2Translated * cosAngle

    // Translate points back to original position
    const x1Final = x1Rotated + centerX
    const y1Final = y1Rotated + centerY
    const x2Final = x2Rotated + centerX
    const y2Final = y2Rotated + centerY

    return [[x1Final, y1Final], [x2Final, y2Final]]
  }

  createArrowPaths(x, y, r, angle, pointiness = 0.3, indent = 0.4) {
    const opposingAngle = (angle + 180) % 360
    const pointVec = 60 * (1 - pointiness)
    const angles = []

    angles.push([1, (angle * Math.PI) / 180]) // base
    angles.push([1, ((opposingAngle + pointVec) * Math.PI) / 180]) // left wing
    if(indent) angles.push([indent, (opposingAngle * Math.PI) / 180]) // indent
    angles.push([1, ((opposingAngle - pointVec) * Math.PI) / 180]) // right wing

    return angles.map(([rr, a]) => [
      x + (r * rr) * Math.cos(a),
      y + (r * rr) * Math.sin(a),
    ])
  }
}



export class WorldLayerCanvas {
  createCanvas() {
    this.canvas = document.createElement("canvas")
    this.ctx = this.canvas.getContext("2d")
    this.dom.append(this.canvas)
  }

  adjustCanvas() {
    if(!this.canvas) return this;
    this.canvas.width = this.attachTo.offsetWidth
    this.canvas.height = this.attachTo.offsetHeight
    return this
  }

  clear() {
    // if(this.culled) console.log("culled", this.culled, this)
    this.culled = 0
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }

  trx(...args) {
    let opts = {}
    let func = null
    args.forEach(arg => {
      if(typeof arg == "function") {
        func = arg
      } else if(typeof arg == "object") {
        opts = arg
      } else {
        console.warn("unknown trx arg", typeof arg, arg)
      }
    })

    if(!func) return
    this.ctx.save()
    this.setupCtx?.(this.ctx)
    func(this.ctx)
    this.ctx.restore()
  }

  applyCtxOpts(opts = {}) {
    if(opts.constructor.name == "Map") {
      opts.forEach((value, key) => this.applyCtxOpt(key, value))
    } else {
      Object.entries(opts).forEach(([key, value]) => this.applyCtxOpt(key, value))
    }
  }

  applyCtxOpt(key, value) {
    if (key == "lw") key = "lineWidth"
    if (key == "color") key = "strokeStyle"
    if (key == "fill") key = "fillStyle"
    this.ctx[key] = value
  }



  drawImage(img, ...args) {
    let opts = {}
    const x = args[args.length - 1]
    if(typeof x === 'object' && !Array.isArray(x) && x !== null) {
      opts = args.pop()
    }

    if(args.length == 1) {
      const tp = this.translateGameVirtual(args[0])
      this.ctx.drawImage(img, tp[0], tp[1])
    } else if (args.length == 3) {
      const tp = this.translateGameVirtual(args[0])
      const twh = [args[1] / this.world.realScale, args[2] / this.world.realScale]

      if(opts.rotate) {
        const centerX = tp[0] + twh[0] / 2
        const centerY = tp[1] + twh[1] / 2
        this.ctx.translate(centerX, centerY)
        this.ctx.rotate(opts.rotate * Math.PI / 180)
        if(opts.clearBox) this.ctx.clearRect(-twh[0] / 2, -twh[1] / 2, twh[0], twh[1])
        this.ctx.drawImage(img, -twh[0] / 2, -twh[1] / 2, twh[0], twh[1])
      } else {
        if(opts.clearBox) this.ctx.clearRect(tp[0], tp[1], twh[0], twh[1])
        this.ctx.drawImage(img, tp[0], tp[1], twh[0], twh[1])
      }
    } else if (args.length == 7) {
      const tp = this.translateGameVirtual(args[4])
      this.ctx.drawImage(img, args[0], args[1], args[2], args[3], tp[0], tp[1], args[5], args[6])
    } else {
      throw("invalid arguments, expect 2, 4 or 8 and got " + args.length)
    }
  }

  createText(...args) {
    return new CanvasTextElement(this.ctx, ...args).setCoordTranslateFunction((x, y) => {
      if(y === undefined) {
        return x * this.world.realScale
      } else {
        return this.translateGameVirtual([x, y])
      }
    })
  }

  strokeText(text, p, opts = {}, bare = false) {
    const pt = this.translateGameVirtual(p)
    !bare && this.ctx.save()
    !bare && this.applyCtxOpts(opts)
    this.ctx.strokeText(text, pt[0], pt[1])
    !bare && this.ctx.restore()
  }

  fillText(text, p, opts = {}, bare = false) {
    const pt = this.translateGameVirtual(p)
    !bare && this.ctx.save()
    !bare && this.applyCtxOpts(opts)
    this.ctx.fillText(text, pt[0], pt[1])
    !bare && this.ctx.restore()
  }

  drawLine(p1, p2, opts = {}, bare = false) {
    const p1t = this.translateGameVirtual(p1)
    const p2t = this.translateGameVirtual(p2)

    if(!this.doBoxesIntersect(this.viewportRect.topleft, this.viewportRect.bottomright, p1, p2)) {
      this.culled += 1
      // console.log("culled line")
      return false
    }

    !bare && this.ctx.save()
    !bare && this.applyCtxOpts(opts)
    this.ctx.beginPath()
    this.ctx.moveTo(...p1t)
    this.ctx.lineTo(...p2t)
    this.ctx.stroke()
    !bare && this.ctx.restore()
    return true
  }

  drawCircle(p, radius = 5000, opts = {}, bare = false) {
    const pt = this.translateGameVirtual(p)
    if(Tmap.get(opts, "scaled")) radius /= Math.max(this.world.vscale, 1)

    !bare && this.ctx.save()
    !bare && this.applyCtxOpts(opts)
    this.ctx.beginPath()
    this.ctx.arc(pt[0], pt[1], this.translateGameVirtualRadius(radius), 0, 2 * Math.PI, false)
    if (![undefined, null, "transparent", "none"].includes(Tmap.get(opts, "color"))) this.ctx.stroke()
    if (![undefined, null, "transparent", "none"].includes(Tmap.get(opts, "fill"))) this.ctx.fill()
    !bare && this.ctx.restore()
  }

  drawPath(path, opts = {}, bare = false) {
    !bare && this.ctx.save()
    !bare && this.applyCtxOpts(opts)
    this.ctx.beginPath()

    for (var i = 0; i < path.length; i++) {
      const tp = this.translateGameVirtual(path[i])
      if(i == 0) {
        this.ctx.moveTo(tp[0], tp[1])
      } else {
        this.ctx.lineTo(tp[0], tp[1])
      }
    }
    this.ctx.closePath()

    if (opts.alwaysStroke || ![undefined, null, "transparent", "none"].includes(Tmap.get(opts, "color"))) this.ctx.stroke()
    if (opts.alwaysFill || ![undefined, null, "transparent", "none"].includes(Tmap.get(opts, "fill"))) this.ctx.fill()
    !bare && this.ctx.restore()
  }

  drawX(p, width = null, scaled = null, opts = {}, bare = false) {
    width ??= 20000
    scaled ??= true
    if(scaled) width /= Math.max(this.world.vscale, 1)
    const hw = width / 2

    let l1 = [[p[0] - hw, p[1]], [p[0] + hw, p[1]]]
    let l2 = [[p[0], p[1] - hw], [p[0], p[1] + hw]]
    const rot = Tmap.delete(opts, "rotate")
    if(rot) {
      l1 = this.rotateLineAroundCenter(l1[0], l1[1], rot)
      l2 = this.rotateLineAroundCenter(l2[0], l2[1], rot)
    }
    this.drawLine(l1[0], l1[1], opts, bare)
    this.drawLine(l2[0], l2[1], opts, bare)
  }

  drawX45(p, width = null, scaled = null, opts = {}, bare = false) {
    Tmap.set(opts, "rotate", 45)
    this.drawX(p, width, scaled, opts, bare)
  }

  drawBox(box, ...args) {
    return this.drawRect(box.slice(0, 2), box[2], box[3], ...args)
  }

  clearRect(p, width, height, opts = {}, bare = false) {
    !bare && this.ctx.save()
    !bare && this.applyCtxOpts(opts)
    this.ctx.beginPath()

    const tp = this.translateGameVirtual(p)
    const tw = width / this.world.realScale
    const th = height / this.world.realScale
    this.ctx.clearRect(tp[0], tp[1], tw, th)
    !bare && this.ctx.restore()
  }

  drawRect(p, width, height, opts = {}, bare = false) {
    !bare && this.ctx.save()
    !bare && this.applyCtxOpts(opts)
    this.ctx.beginPath()

    const tp = this.translateGameVirtual(p)
    const tw = width / this.world.realScale
    const th = height / this.world.realScale
    this.ctx.rect(tp[0], tp[1], tw, th)

    if (opts.alwaysStroke || ![undefined, null, "transparent", "none"].includes(Tmap.get(opts, "color"))) this.ctx.stroke()
    if (opts.alwaysFill || ![undefined, null, "transparent", "none"].includes(Tmap.get(opts, "fill"))) this.ctx.fill()
    !bare && this.ctx.restore()
  }

  drawCenterCrosshair(width = null, scaled = null, opts = {}, bare = false) {
    this.drawX45([0, 0], width, scaled, opts, bare)
  }
}



class WorldLayerUtility {
  renderDebounce(delay, mode = "hide", func = "render") {
    if(this.debounces[func]) clearTimeout(this.debounces[func])
    if(mode == "fade") this.dom.style.opacity = 0
    if(mode == "hide") this.dom.classList.add("d-none")
    this.debounces[func] = setTimeout(_ => {
      if(mode == "fade") this.dom.style.opacity = 1
      if(mode == "hide") this.dom.classList.remove("d-none")
      mode == "async" ? this.renderAsync() : this.render()
    }, delay)
  }

  renderWithDebounce() {
    if(this.debounceRenderWithFade !== undefined && this.debounceRenderWithFade !== null) {
      this.renderDebounce(this.debounceRenderWithFade, "fade")
    } else if (this.debounceRender !== undefined && this.debounceRender !== null) {
      this.renderDebounce(this.debounceRender)
    } else if (this.debounceRenderAsync !== undefined && this.debounceRenderAsync !== null) {
      this.renderDebounce(this.debounceRenderAsync, "async")
    } else {
      this.render()
    }
    return this
  }

  formatNumber (s, decimals = 0) {
    if (decimals) {
      return s.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return s.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\.\d+/, "")
    }
  }

  generateShades(baseColor, numberOfShades) {
    // Helper function to convert hex to HSL
    function hexToHSL(hex) {
      hex = hex.replace(/^#/, '');
      if (hex.length === 3) {
        hex = hex.split('').map(x => x + x).join('');
      }

      const r = parseInt(hex.substr(0, 2), 16) / 255;
      const g = parseInt(hex.substr(2, 2), 16) / 255;
      const b = parseInt(hex.substr(4, 2), 16) / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }

      return [h * 360, s, l];
    }

    // Helper function to convert HSL to hex
    function hslToHex(h, s, l) {
      const a = s * Math.min(l, 1 - l);
      const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(color * 255).toString(16).padStart(2, '0'); // convert to Hex and pad with '0'
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    }

    // Get base color HSL values
    const [h, s, l] = hexToHSL(baseColor);

    // Generate shades by varying the lightness
    const shades = [];
    for (let i = 0; i < numberOfShades; i++) {
      const lightness = (i + 1) / (numberOfShades + 1);
      shades.push(hslToHex(h, s, lightness));
    }

    return shades;
  }

  hsvToRgb(h, s, v) {
    h = h % 1.0 // Ensure h is in the range [0, 1)
    let i = Math.floor(h * 6)
    let f = h * 6 - i
    let p = v * (1 - s)
    let q = v * (1 - f * s)
    let t = v * (1 - (1 - f) * s)

    let r, g, b;
    switch (i % 6) {
      case 0: r = v; g = t; b = p; break;
      case 1: r = q; g = v; b = p; break;
      case 2: r = p; g = v; b = t; break;
      case 3: r = p; g = q; b = v; break;
      case 4: r = t; g = p; b = v; break;
      case 5: r = v; g = p; b = q; break;
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
  }

  rgbToHex(r, g, b) {
    const toHex = component => component.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase()
  }
}



// export class WorldLayerWithoutCanvas extends Classes(WorldLayerCore, WorldLayerOptreg, WorldLayerMath, WorldLayerUI, WorldLayerCanvas) {}
export class WorldLayer extends Modules(WorldLayerUtility, WorldLayerOptreg, WorldLayerMath, WorldLayerUI, WorldLayerCanvas) {
  constructor(world, opts = {}, ...args) {
    super()
    this.dependencies = []
    this.opts = opts
    this.world = world
    this.setupOptreg({ namespace: `lo.${this.constructor.name}`, target: this.world })
    this.enabled = false
    this.hasCanvas = true
    this.attachInitially = true
    this.enableInitially = false
    this.enableUiTogglesWhenReady = true
    this.autoshowHUD = true
    this.mode = "fg"
    this.afterOChange = "update"
    this.debounces = {}
    this.attachTo = this.world.worldParentEl
    this.initPre?.()
    this.createDom()
    this.init?.(...args)
    if (this.hasCanvas) this.createCanvas()
    if (this.attachInitially) this.attach()
    if (this.debounceRenderWithFade) this.dom.classList.add("fadeable")
    this.initPost?.()
  }

  dependsOn(what) { this.dependencies.push(what) }
  dependsOnIslands() { this.dependsOn(this.world.islands.allIslandsHaveLoaded) }

  get ui() { return this.world.ui }
  get assets() { return this.world.assets }
  get network() { return this.world.network }
  getLayer(lname) { return this.world.layer(lname) }
  get worldRect() { return this.world.worldRect }
  get worldParentRect() { return this.world.worldParentRect }
  get viewportRect() { return this.world.viewportVirtualRect }
  translateViewport(...args) { return this.world.translateViewportToReal(...args) }
  translateClient(...args) { return this.world.translateClientToReal(...args) }
  translateGameVirtual(...args) { return this.world.translateGameToViewport(...args) }
  translateGameVirtualBox(...args) { return this.world.translateGameToViewportBox(...args) }
  translateGameVirtualRadius(...args) { return this.world.translateGameRadiusToViewport(...args) }
  getRegionFromPoint(...args) { return this.world.getRegionFromPoint(...args) }
  pointToGridLocation(...args) { return this.world.pointToGridLocation(...args) }

  // api
  initPre() {}
  init() {}
  initPost() {}
  startup() {}
  ready() {
    if (this.enableUiTogglesWhenReady) $(`[data-layer-toggle="${this.constructor.name}"]`).show()
    if (this.enableInitially) this.enable()
  }
  texturesLoaded() { this.update() }
  update(ev) { return this.renderWithDebounce() }
  render() { return this }
  async renderAsync(...args) { return this.render(...args) }
  onEnable() {}
  onDisable() {}

  toggle() { this.enabled ? this.disable() : this.enable() }
  enable() { return this.enableWithDependencies() }
  enableWithDependencies() {
    if(this.dependencies.length) {
      return Promise.all(this.dependencies).then(_ => this.enableWithoutDependencies())
    } else {
      return this.enableWithoutDependencies()
    }
  }
  enableWithoutDependencies() {
    if(this.enabled) return false
    if(this.beforeEnable && this.beforeEnable() === false) return false
    this.enabled = true
    this.show()
    if(this.autoshowHUD) this.showHUD()
    this.updateLayerHUDToggle()
    this.onEnable?.()
    this.world.layerEnabled(this)
    this.update?.()
  }
  disable() {
    if(!this.enabled) return
    this.enabled = false
    this.hide()
    if(this.autoshowHUD) this.hideHUD()
    this.updateLayerHUDToggle()
    this.world.layerDisabled(this)
    this.onDisable?.()
  }


  // world events
  // handleParentKeydown(ev) {}
  // handleParentKeyup(ev) {}
  // handleParentMouseMove(ev) {}
  handleParentResize(el, observer) {
    this.adjustCanvas()
    this.update()
  }
}
