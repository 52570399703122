import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input--rewrite-pvlink"
export default class extends Controller {
  static targets = ["input", "trigger"]
  static urlRegex = /^\/games\/sot\/profile-viewer\/(my-profile|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|[0-9a-f]{32})([\/|?](?:.*))?$/i

  connect() {
  }

  inputTargetConnected(el) {
    //el.addEventListener("keydown", ev => this.process(ev))
    el.addEventListener("keyup", ev => this.process(ev))
    el.addEventListener("change", ev => this.process(ev))
    this.process()
  }

  replaceValue(ev) {
    ev.preventDefault()

    const val = this.inputTarget.value
    const m = this.getMatch(val)
    if(!m) return false
    this.inputTarget.value = `%profile%${m[2] ? m[2] : ""}`
  }

  process(ev) {
    const val = this.inputTarget.value

    this.triggerTarget.classList.add("d-none")
    this.inputTarget.classList.remove("border-warning")
    this.inputTarget.classList.remove("border-success")

    if(!val) return false // no input

    const m = this.getMatch(val)
    if(m === null && val.startsWith("%profile%")) return this.inputTarget.classList.add("border-success")
    if(m === null) return this.inputTarget.classList.add("border-warning")
    if(!m) return false
    this.inputTarget.classList.add("border-success")
    this.triggerTarget.classList.remove("d-none")
  }

  getMatch(val) {
    try {
      const uri = URL.parse(val)
      if(!uri) return null
      if(uri.host != location.host) return false
      const path = uri.pathname + uri.search + uri.hash
      const m = path.match(this.constructor.urlRegex)
      if(!m) return false
      return m
    } catch(e) {
      console.warn(e)
      return null
    }
  }
}
