export class Performance {
  constructor(opts = {}) {
    this.opts = Object.assign({}, {
    }, opts)
    this.enabled = true
    this.data = new Map()
  }

  static WORLD_METHODS_TO_TIME = [
    /^handle/,
  ]

  static LAYER_METHODS_TO_TIME = [
    "update", "render", "updateZoom", "updatePan",
    /^handle/,
  ]

  setup(target, enable) {
    if(!this.enable(enable).enabled) return false
    if($(`[data-hudval="debug.performance"]`).length) setInterval(_ => $(`[data-hudval="debug.performance"]`).html(this.toDebugHtmlTable()), 3000)

    this.timeInstanceFunctions(target, meth => {
      if(this.constructor.WORLD_METHODS_TO_TIME.some(wm => {
        return wm instanceof RegExp ? meth.match(wm) : meth == wm
      })) return `__WORLD__#${meth}`
      // else console.log("world", meth)
    })

    target.layers.forEach((l, k) => {
      this.timeInstanceFunctions(l, meth => {
        if(this.constructor.LAYER_METHODS_TO_TIME.some(wm => {
          return wm instanceof RegExp ? meth.match(wm) : meth == wm
        })) return `${k}#${meth}`
        // else console.log(k, meth)
      })
    })
  }

  enable(setTo = true) {
    this.enabled = !!setTo
    return this
  }

  disable() {
    this.enabled = false
    return this
  }

  timeInstanceFunctions(target, filterFn) {
    const prototype = Object.getPrototypeOf(target)
    Object.getOwnPropertyNames(prototype).forEach(meth => {
      let key = `${target.constructor?.name ?? "Obj"}#${meth}`
      if(filterFn) key = filterFn(meth)
      if(!key) return undefined
      const native = target[meth].bind(target)
      target[meth] = (...args) => this.measure(key, _ => { native(...args) })
    })
  }

  measure(id, fn) {
    if(!this.enabled) return fn()
    const m = this.data.get(id) ?? [0, Infinity, -Infinity, 0.0] // [invocations, min, max, avg]
    const start = performance.now()
    fn()
    const rt = performance.now() - start

    if(rt < m[1]) m[1] = rt
    if(rt > m[2]) m[2] = rt
    m[3] = (m[0] * m[3] + rt) / (m[0] + 1)
    m[0] += 1

    this.data.set(id, m)
  }

  resetMinMax() {
    this.data.forEach((v, k) => {
      v[1] = Infinity
      v[2] = -Infinity
    })
  }

  reset() {
    this.data.forEach((v, k, m) => m.delete(k))
  }

  forEach(...args) { return this.data.forEach(...args) }
  sortedArray(sIndex = 3) { return Array.from(this.data).sort((a, b) => b[1][sIndex] - a[1][sIndex]) }

  toDebugHtmlTable() {
    let build = `<table class="text-end font-monospace"><thead><tr><th>rep</th><th>min</th><th>max</th><th>avg</th><th>?</th></tr></thead><tbody>`
    this.sortedArray().forEach(([id, m]) => {
      build += `<tr><td>${m[0]}</td><td>${m[1].toFixed(3)}</td><td>${m[2].toFixed(3)}</td><td>${m[3].toFixed(3)}</td><td>${id}</td></tr>`
    })
    build += "</tbody></table>"
    return build
  }
}
