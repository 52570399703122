export class OptionRegistry {
  constructor(opts = {}) {
    this.opts = Object.assign({}, {
      namespace: null,
      defaultSync: { ui: 0, url: 0, net: 0 },
    }, opts)
    this.onValueChangeCallbacks = []
    this.registry = new RegisteredMap()
  }

  fireOnValueChangeCallbacks(opt, oldValue, newValue) {
    this.onValueChangeCallbacks.forEach(([f, cb]) => {
      if(f && opt.name != f) return false
      f ? cb(newValue, oldValue, opt, opt.name) : cb(opt.name, newValue, oldValue, opt)
    })
  }

  onValueChange(...args) {
    if(args.length == 1) {
      this.onValueChangeCallbacks.push([null, args[0]])
    } else if (args.length == 2) {
      this.onValueChangeCallbacks.push([args[0], args[1]])
    } else {
      throw(`expected 1 or 2 arguments, got ${args.length}`)
    }
  }

  serializeUrlParams(into, opts = {}) {
    opts.sync ??= 1
    opts.includeDefault ??= false
    opts.includeCommands ??= false

    into ??= []
    this.registry.forEach((opt, key) => {
      opt.serializeUrlParam(into, opts)
    })
    return into
  }

  add(type, name, def = null, opts = {}) {
    const [ptr, lk] = this.get(name, false, true, true)

    opts.sync ??= Object.assign({}, this.opts.defaultSync)
    const opt = new RegisteredOption(this, type, name, def, opts)
    ptr.set(lk, opt)
    return opt
  }

  cmd(name, ...args) {
    let opts = {}
    if(args.length == 2) {
      opts = args[0]
      opts.commandCallback = args[1]
    } else if (args.length == 1) {
      opts.commandCallback = args[0]
    }
    return this.add("cmd", name, false, opts)
  }

  set(name, value) {
    const opt = this.get(name)
    if(!opt) throw(`cannot set unknown option ${name} to ${value}`)
    opt.value = value
  }

  toggleValue(name) {
    const opt = this.get(name)
    if(!opt) throw(`cannot toggle unknown option ${name}`)
    opt.toggleValue()
  }

  get(name, fail = false, outer = false, createOuter = false) {
    let ptr = this.registry
    const chunks = name.split(".")
    const lk = chunks[chunks.length - 1]
    if(chunks.length > 1) {
      for (var i = 0; i < chunks.length - 1; i++) {
        if(!ptr.has(chunks[i]) && createOuter) ptr.set(chunks[i], new RegisteredMap())
        ptr = ptr.get(chunks[i])
        if(!ptr) {
          if(!fail) return outer ? [null, null] : false
          throw(`unknown option '${name}' (when accessing '${chunks[i]}')`)
        }
      }
    }
    if(!createOuter && (!ptr || !ptr.has(lk))) {
      if(!fail) return outer ? [null, null] : false
      throw(`unknown option '${name}' (when accessing '${lk}')`)
    }
    return outer ? [ptr, lk] : ptr.get(lk)
  }

  fetch(name, def) {
    const v = this.get(name)?.value
    return typeof v == "undefined" ? def : v
  }
}



class RegisteredMap extends Map {
  get value() {
    const result = new Map()
    this.forEach((v, k) => result.set(k, v.value))
    return result
  }

  serializeUrlParam(into, opts = {}) {
    this.forEach((opt, key) => {
      opt.serializeUrlParam(into, opts)
    })
    return into
  }
}



class RegisteredOption {
  constructor(registry, type, name, def = null, opts = {}) {
    this.initialized = false
    this.registry = registry
    this.type = type
    this.name = name
    this.opts = Object.assign({}, {
      allowNull: false,
      sync: { ui: 0, url: 0, net: 0 },
    }, opts)
    this.defaultValue = this.typeCast(def)
    this.value = def // typecast setter
    this.initialized = true
  }

  onChange(...args) {
    this.registry.onValueChange(this.name, ...args)
  }

  get fullKey() {
    const ns = this.registry.opts.namespace
    return ns ? `${ns}.${this.name}` : this.name
  }

  set(v) { return this.value = v }
  reset() { return this.set(this.defaultValue) }

  isDefault() { return this.defaultValue == this.currentValue}
  get boolish() { return this.type == "bool" || this.type == "cmd" || this.type == "enum" }
  get value() { return this.currentValue }
  set value(v) {
    const nv = this.typeCast(v)
    if(nv !== this.currentValue) {
      const ov = this.currentValue
      this.currentValue = nv
      this.emitChange(ov, nv)
    }
    return this.currentValue
  }

  emitChange(...args) {
    while(args.length < 2) args.push(this.currentValue)
    this.registry.fireOnValueChangeCallbacks(this, ...args)
    return this
  }

  typeCast(v) {
    if(this.opts.allowNull && (v === null || v === "__null__")) return null
    else if(this.type == "int") v = parseInt(v)
    else if(this.type == "float") v = parseFloat(v)
    else if(this.type == "bool" && typeof v != "boolean") v = [true, "true", "1", "on", "yes"].includes(v)
    else if(this.type == "date") v = new Date(Date.parse(v))
    else if(this.type == "enum") {
      const ei = this.opts.enum.indexOf(v)
      if(ei < 0) throw(`invalid enum value '${v}', allowed: [${this.opts.enum}]`)
      v = ei
    }

    if(this.opts.castNanToNull && isNaN(v)) v = null
    return v
  }

  get formattedValue() {
    if(this.opts.allowNull && this.currentValue === null) return "null"
    if(this.type == "float" && this.opts.precision !== 0) return this.currentValue.toFixed(this.opts.precision ?? 2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    if(this.type == "int" || this.type == "float") return this.currentValue.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\.\d+/, "")
    if(this.type == "enum") return this.enumValue
    if(this.type == "date") return this.value.toISOString()
    return this.currentValue
  }

  get urlValue() {
    if(this.opts.allowNull && this.currentValue === null) return "__null__"
    if(this.type == "float") return this.currentValue.toFixed(this.opts.precision ?? 2)
    if(this.type == "bool") return this.currentValue ? 1 : 0
    if(this.type == "enum") return this.enumValue
    if(this.type == "date") return this.value.toISOString()
    return this.currentValue
  }

  serializeUrlParam(into = [], opts = {}) {
    const urlSync = this.getSync("url")
    if(!opts.includeCommands && this.type == "cmd") return into
    if((!opts.removeUnsynced || !opts.source) && urlSync < (opts.sync ?? 1)) return into
    if(!opts.source && !opts.include && this.isDefault()) return into
    const key = this.fullKey

    if(opts.removeUnsynced && urlSync < (opts.sync ?? 1)) {
      if(opts.source?.[key]) into.push([key, undefined])
    } else if(!opts.includeDefault && this.isDefault()) {
      if(opts.source?.[key]) into.push([key, undefined])
    } else {
      into.push([key, this.urlValue])
    }
    return into
  }

  toggleValue(...args) {
    if(this.type == "cmd") {
      return this.opts.commandCallback(this, ...args)
    } else if(this.type == "bool") {
      return this.value = !this.currentValue
    } else if(this.type == "enum") {
      return this.value = this.nextEnumValue()
    } else {
      throw(`Can only toggle values of type bool, enum or cmd but type of '${this.name}' is ${this.type}`)
    }
  }



  // =============
  // = Enum only =
  // =============
  get enumValue() { return this.opts.enum[this.currentValue] }
  set enumValue(v) {
    if(v != this.currentValue) {
      const ov = this.currentValue
      this.currentValue = v
      this.emitChange(ov, v)
    }
    return this.currentValue
  }

  nextValue() {
    if(this.value + 1 >= this.opts.enum.length) return 0
    return this.value + 1
  }

  previousValue() {
    if(this.value <= 0) return this.opts.enum.length - 1
    return this.value - 1
  }

  nextEnumValue() { return this.opts.enum[this.nextValue()] }
  previousEnumValue() { return this.opts.enum[this.previousValue()] }



  // ========
  // = Sync =
  // ========
  fakeSync(sync = {}, cb) {
    const fakeSyncWas = this.fakeSync
    try {
      this.fakeSync = sync
      cb?.()
    } finally {
      this.fakeSync = fakeSyncWas
    }
  }

  getSync(k, l) {
    const sync = this.fakeSync[k] ?? this.opts.sync[k]
    return l ? sync >= l : sync
  }

  desync() {
    return this.ui(0).url(0).net(0)
  }

  sync(v) {
    if(v === undefined) return this.opts.sync
    return this.ui(v).url(v).net(v)
  }

  ui(v) {
    if(v === undefined) return this.opts.sync.ui
    this.opts.sync.ui = v
    return this
  }

  url(v) {
    if(v === undefined) return this.opts.sync.url
    this.opts.sync.url = v
    return this
  }

  net(v) {
    if(v === undefined) return this.opts.sync.net
    this.opts.sync.net = v
    return this
  }
}
