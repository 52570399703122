import consumer from "../../../../channels/consumer"

import { WorldLayer } from "../world_layer"

export class Network extends WorldLayer {
  init() {
    //this.enableInitially = true
    this.mayRenderAsync = true

    // [connect]/[disconnect][director]

    this.optreg.add("str", "roomId", "").sync(2).onChange(v => v && this.earlyPhaseAutoconnect())
    this.optreg.add("str", "name", "").sync(2).onChange(v => v && this.earlyPhaseAutoconnect())
    this.optreg.add("bool", "connectionModal", true).sync(0).onChange(v => this.connectModal())
    this.optreg.add("bool", "syncAnnotate", true).onChange(v => this.send({ "foo": "bar"}))
    this.optreg.add("bool", "syncDirector", false).onChange(v => this.send({ "foo": "bar"}))

    this.modalEnabled = false
    this.earlyPhase = true
    setTimeout(_ => { this.earlyPhase = false }, 5000)

    this.setupModals()
  }

  onEnable() {
    if(!this.subscription) {
      this.uiConnectReset()
      this.connectModal()
    }
  }

  earlyPhaseAutoconnect() {
    const modal = this.uiModalConnect
    if(!this.earlyPhase) return false

    if(this.o("name")) modal.find(`input[name="name"]`).val(this.o("name"))
    if(this.o("roomId")) modal.find(`input[name="roomId"]`).val(this.o("roomId"))

    if(!this.o("name") || !this.o("roomId")) return false

    if(!this.modalEnabled) {
      modal.one("shown.bs.modal", _ => this.uiModalConnect.find("form").submit())
      if(!this.enabled) this.enable()
      this.connectModal()
    } else {
      this.uiModalConnect.find("form").submit()
    }
  }

  handleParentKeydown(ev) {
    if(!this.enabled) return true
    if(this.modalEnabled) return false
  }

  handleParentKeyup(ev) {
    if(!this.enabled) return true
    if(this.modalEnabled) return false
  }

  setupModals() {
    const modal = this.uiModalConnect

    modal.on("show.bs.modal", ev => { this.modalEnabled = true })
    modal.on("hidden.bs.modal", ev => {
      this.modalEnabled = false
      if(!this.subscription) this.disable()
    })

    const iForm = modal.find(`form`)
    const iName = modal.find(`input[name="name"]`)
    const iRoom = modal.find(`input[name="roomId"]`)

    iForm.submit(ev => {
      ev.preventDefault()
      this.uiConnectReset()

      if(!iName.val()?.trim()) {
        iName.addClass("is-invalid")
        return
      }

      // create room = random uuid
      if(iForm.data("createNewRoom")) {
        iRoom.val(crypto.randomUUID())
        iForm.removeData("createNewRoom")
      }

      if(!iRoom.val()?.trim()) {
        iRoom.addClass("is-invalid")
        return
      }

      this.uiConnectConnecting()
      this.connect(iRoom.val().trim(), iName.val().trim())
    })

    modal.find(`[data-action="create"]`).click(ev => { iForm.data("createNewRoom", true) })
  }

  uiConnectConnecting() {
    const modal = this.uiModalConnect
    modal.find(`fieldset`).attr("disabled", "disabled")
    modal.find(`[data-role="connStatusSpinner"]`).removeClass("invisible")
    modal.find(`[data-role="connStatusText"]`).removeClass("d-none")
    modal.find(`[data-role="connStatusWait"]`).addClass("d-none")
    this.uiOptConnStatus.removeClass((i, c) => (c.match(/(^|\s)btn-\S+/g) || []).join(' ')).addClass("btn-warning")
    this.uiOptConnStatusText.text("[connecting]")
  }

  uiConnectConnected() {
    const modal = this.uiModalConnect
    modal.find(`[data-role="connStatusText"]`).text("connected")
    modal.find(`[data-role="connStatusSpinner"]`).addClass("invisible")
    modal.find(`[data-action="disconnect"]`).removeClass("d-none")
    this.uiOptConnStatus.removeClass((i, c) => (c.match(/(^|\s)btn-\S+/g) || []).join(' ')).addClass("btn-success")
    this.uiOptConnStatusText.text("[connected]")
  }

  uiConnectReset() {
    const modal = this.uiModalConnect
    this.clearStateContainers()
    modal.find(`fieldset`).removeAttr("disabled")
    modal.find(`input[name="name"]`).removeClass("is-invalid")
    modal.find(`input[name="roomId"]`).removeClass("is-invalid")
    modal.find(`[data-role="connStatusSpinner"]`).addClass("invisible")
    modal.find(`[data-role="connStatusText"]`).addClass("d-none").text("connecting")
    modal.find(`[data-action="disconnect"]`).addClass("d-none")
    modal.find(`[data-role="connStatusWait"]`).removeClass("d-none")
    this.uiOptConnStatus.removeClass((i, c) => (c.match(/(^|\s)btn-\S+/g) || []).join(' ')).addClass("btn-danger")
    this.uiOptConnStatusText.text("[disconnected]")
  }

  get uiOptConnStatus() { return $(`[data-layer="${this.constructor.name}"][data-layer-opt-toggle="connectionModal"]`) }
  get uiOptConnStatusText() { return $(`[data-layer="${this.constructor.name}"][data-layer-opt-toggle="connectionModal"] span`) }

  get uiModalConnect() { return $(`[data-role="world:network:connect"]`) }

  connectModal() {
    const modal = bootstrap.Modal.getOrCreateInstance(this.uiModalConnect)
    modal.show()
  }

  stateContainer(role) {
    let sel = `[data-is="netstate"]`
    if(role) sel += `[data-role="${role}"]`
    return $(sel)
  }

  clearStateContainers(role) {
    return this.stateContainer(role).html("")
  }

  disconnect() {
    if(!this.subscription) return false
    this.subscription.unsubscribe()
    this.oo("name").reset()
    this.oo("roomId").reset()
    delete this.subscription
    delete this.net
    delete this.state
    this.uiConnectReset()
  }

  connect(room, name) {
    const ref = this
    this.disconnect()
    if(!room) return false

    console.log("connecting to room", room)
    this.state = {}
    this.net = { connected: false, id: null }
    this.earlyPhase = false
    this.subscription = consumer.subscriptions.create({
      channel: "Games::Sot::WorldChannel",
      room_id: room,
      name: name,
    }, {
      connected(a, b, c) {
        ref.net.connected = true
        console.log("connected")
        ref.uiModalConnect.find(`[data-role="connStatusText"]`).text("connected, waiting for data")
        setTimeout(_ => ref.synchronize(), 10)
        // $(this.worldEl).trigger("sot:sync:connected")
      },

      disconnected(a, b, c) {
        ref.net.connected = false
        console.log("disconnected")
        // $(this.worldEl).trigger("sot:sync:disconnected")
      },

      received(data, b, c) {
        data.forEach(([instruct, payload]) => {
          ref.receive(instruct, payload)
        })
      }
    })
  }

  send(data) {
    this.subscription.perform("receive", data)
  }

  emit(action, data) {
    this.subscription.perform("emit_layer_action", { laction: action, data: data })
  }

  synchronize() {
    this.subscription.perform("synchronize!")
  }

  chat(message) {
    if(!this.net?.id) return false
    this.subscription.perform("chat", {
      author: this.net.id,
      message: message,
    })
  }

  createParticipantTable() {
    const table = $(`<table><thead><tr><th>name</th><th>uuid</th></tr></thead><tbody></tbody></table>`).addClass("table table-sm mb-0")
    this.clearStateContainers("participants").append(table)
    Object.entries(this.state.active_connections).forEach(([uuid, name]) => {
      this.addParticipant(uuid, name)
    })
  }

  addParticipant(uuid, name) {
    const tbody = this.stateContainer("participants").find("table tbody")
    const tr = $("<tr>").attr("data-uuid", uuid)
    tr.append($(`<th>`).text(name))
    tr.append($(`<td>`).text(uuid))
    tbody.append(tr)
  }

  removeParticipant(uuid) {
    const tbody = this.stateContainer("participants").find("table tbody")
    tbody.find(`tr[data-uuid="${uuid}"]`).remove()
  }

  receive(action, data) {
    console.log(action, data)
    if(action.startsWith("l:")) {
      const chonks = action.split(":")
      console.log(chonks, data)
      const layer = this.ui.getLayerOrToast(chonks[1])
      if(layer) layer[chonks[2]](...data)
    } else if(action == "state:net") {
      Object.entries(data).forEach(([key, value]) => {
        this.net[key] = value
      })
      this.oo("name").set(this.net.name)
      this.oo("roomId").set(this.net.room)
    } else if (action == "state:replace") {
      this.state = data
      this.uiConnectConnected()
      this.createParticipantTable()
      // setTimeout(_ => {
        bootstrap.Modal.getInstance(this.uiModalConnect)?.hide()
      // }, 1000)

    } else if (action == "participant:add") {
      this.addParticipant(data.id, data.name)
    } else if (action == "participant:remove") {
      this.removeParticipant(data.id)
    } else if (action == "annotate:marker:add") {
      this.getLayer("Annotate")?.addMarker(data.uuid, data.type, data.pos, data.data)
    } else if (action == "annotate:marker:remove") {
      this.getLayer("Annotate")?.deleteMarker(data.uuid)
    } else {
      console.warn("unknown action", action, "with payload", data)
    }
    // if(Array.isArray(data)) {
    //   return data.forEach(d => this.receive(d))
    // }

    // if (data.marker == "add") {
    //   this.markers.push(data.p)
    //   this.update()
    // } else if (data.marker == "clear") {
    //   this.markers.length = -1
    //   this.update()
    // }
  }

  // if(this.)
  // console.log(this.worldEl.sync)
  // this.world.on("sot:sync:data", ev => {
  //   console.log("sot-sync", ev)
  // })

  render() {
    if(!this.enabled) return
    this.clear()

    // this.trx(ctx => {
    //   this.markers.forEach(m => {
    //     this.drawX45(m)
    //   })
    // })
  }
}
