import * as AppOS from "../../../appos"
import { WorldLayer } from "../world_layer"
import { SotIngameTime, SotTimerElement } from "../../sot/ingame_time"

export class GridForeground extends WorldLayer {
  init() {
    this.enableInitially = true
    this.mayRenderAsync = true

    this.children = new Map()

    this.createTimeCtn()
    this.optreg.add("str",   "gridLabelColor", '#3338')
    this.optreg.add("bool",  "renderGridLabels", true)
    this.optreg.add("bool",  "renderCompass", true).onChange(v => this.setCompassOffsets())
    this.optreg.add("bool",  "renderSeanames", true)
    this.optreg.add("bool",  "renderRegions", false)
    this.optreg.add("bool",  "renderClock", true).onChange(v => v ? this.startClock() : this.stopClock())
    this.optreg.add("bool",  "renderFullClock", false).onChange(v => v ? this.enableFullClock() : this.disableFullClock())
    this.optreg.add("float", "startFadingAtScale", 2)
    this.optreg.add("float", "endFadingAtScale", 3.75)
    this.optreg.add("float", "compassSizeRatio", 0.6)

    // this.thr_renderGridLabels = AppOS.Util.debounce(_ => this._renderGridLabels(), 350)
    this.thr_renderGridLabels = this._renderGridLabels

    // fade in when loaded
    this.dom.style.opacity = 0
    this.dom.style.transition = "opacity 250ms linear"
  }

  startup() {
    this.markers.textures.on("load", _ => this.texturesLoaded())
    this.compassAsset = this.markers.textures.image("compass", `compass.png`, { tex: { texture: "compass" } })
    if(this.markers.loaded) this.compassAsset.on("load", _ => this.texturesLoaded()).load()
  }

  ready() {
    super.ready()
    this.timeInstance ??= new SotTimerElement(this.timeCtnFull, {
      clockHandlesFilter: n => { return (n+1) % 3 != 0 }, // main
    })
    this.timeInstance.el = this.timeCtn
    if(this.o("renderClock")) this.startClock()
  }

  onEnable() {
    if(!this.regionCache) {
      this.regionCache = new Map()
      this.world.regions.forEach(regdata => {
        this.regionCache.set(regdata.region, { name: regdata.name, p: regdata.center, r: regdata.radius, f: regdata.color ?? "rgba(255, 0, 255, 0.5)" })
      })

      // reg.set("test", {
      //   name: "test",
      //   p: [-72262.0078125, -162334.859375],
      //   r: 38000000.0,
      //   c: "magenta",
      //   f: "rgba(255, 0, 255, 0.4)",
      // })
    }

    this.children.forEach((c, k) => c.enable?.())
  }

  onDisable() {
    this.children.forEach((c, k) => c.disable?.())
  }

  adjustCanvas() {
    super.adjustCanvas()
    this.children.forEach((c, k) => c.adjustCanvas?.())
  }

  update(ev) {
    super.update(ev)
    this.children.forEach((c, k) => c.update?.(ev))
  }

  texturesLoaded() {
    this.dom.style.opacity = 1
    this.setCompassOffsets()
    this.update()
  }

  setCompassOffsets() {
    if(!this.compassAsset.loaded) return
    if(this.o("renderCompass")) {
      this.timeCtnCompact.style.width = `${this.compassAsset.opts.tex.image_size[0] * this.o("compassSizeRatio")}px`
      this.timeCtnCompact.style.bottom = `${this.compassAsset.opts.tex.image_size[1] * this.o("compassSizeRatio") - 10}px`
    } else {
      this.timeCtnCompact.style.width = `${this.compassAsset.opts.tex.image_size[0] * this.o("compassSizeRatio")}px`
      this.timeCtnCompact.style.bottom = `15px`
    }
  }

  get bgLayer() { return this.getLayer("GridBackground") }
  co(...args) { return this.bgLayer.o(...args) }
  coo(...args) { return this.bgLayer.oo(...args) }

  startClock() {
    this.timeInstance.start()
    this.timeCtn.classList.remove("invisible")
  }
  stopClock() {
    this.timeInstance.stop()
    this.timeCtn.classList.add("invisible")
  }

  enableFullClock() {
    if(this.timeCtn == this.timeCtnFull) return false
    const wasVisible = !this.timeCtn.classList.contains("invisible")
    if(wasVisible) this.timeCtn.classList.add("invisible")
    this.timeInstance.updateElement(this.timeCtn = this.timeCtnFull)
    if(wasVisible) this.timeCtn.classList.remove("invisible")
  }

  disableFullClock() {
    if(this.timeCtn == this.timeCtnCompact) return false
    const wasVisible = !this.timeCtn.classList.contains("invisible")
    if(wasVisible) this.timeCtn.classList.add("invisible")
    this.timeInstance.updateElement(this.timeCtn = this.timeCtnCompact)
    if(wasVisible) this.timeCtn.classList.remove("invisible")
  }

  get markers() { return this.bgLayer.markers }
  get gwidth() { return this.bgLayer.gwidth }
  get gheight() { return this.bgLayer.gheight }
  get renderedLinesX() { return this.bgLayer.renderedLinesX }
  get renderedLinesY() { return this.bgLayer.renderedLinesY }

  createTimeCtn() {
    this.timeCtnOuter = $(`<div class="position-absolute bottom-0 end-0"></div>`).appendTo(this.dom)

    this.timeCtnCompact = $(`
      <div data-appos-controller="SotIngameTime" style="
        pointer-events: all;
        color: #333c;
        font-size: 0.9rem;
        text-shadow: -1px -1px 0px #62A6EEcc, 1px 1px 0px #62A6EEcc, -1px 1px 0px #62A6EEcc, 1px -1px 0px #62A6EEcc;
      " class="invisible font-windlass-extended position-absolute end-0 d-flex">
        <div style="flex: 1" class="text-end"><span data-v="day"></span></div>
        <div class="px-1">•</div>
        <div style="flex: 1">
          <span data-v="hour"></span>:<span data-v="minute"></span>
        </div>
      </div>
    `).appendTo(this.timeCtnOuter).get(0)

    this.timeCtnFull = $(`
      <div data-appos-controller="SotIngameTime" class="p-2 overflow-hidden invisible" style="pointer-events: all">
        <div class="clock-face-container position-relative" data-v="clockFaceOuter" style="--size: 200px">
          <div class="clock-face position-absolute" data-v="clockFace">
            <div class="position-absolute top-50 start-50 clock-handles" data-v="clockHandles"></div>
            <div class="position-absolute top-50 start-50 clock-notches" data-v="clockNotches"></div>
          </div>
          <div class="clock-static position-absolute" data-v="clockStatic">
            <div class="position-absolute top-0 start-50 now-marker"></div>
            <div class="position-absolute top-50 start-50 center-display rounded">
              <div class="text-center">
                <div class="fw-bold text-small font-windlass-extended">
                  <span data-v="month" class="d-none"></span>
                  <span data-v="day"></span><br>
                </div>
                <div class="fw-bold text-big font-windlass-extended">
                  <span class="position-relative">
                    &thinsp;:&thinsp;
                    <span class="position-absolute start-0 xpe-1" style="transform: translateX(-100%)" data-v="hour"></span>
                    <span class="position-absolute xps-1" data-v="minute"></span>
                  </span>
                  <div class="px-1" style="overflow: hidden; height: 0px">00:00</div>
                </div>

                <div class="text-smaller text-xmuted d-flex justify-content-around mt-1">
                  <div class="d-flex align-items-center">
                    <span data-v="fishPhaseIcon"><svg class="" style="width: 21px;height: 21px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g transform="translate(17,-4)"><path d="M136.6 52.93c20.2 21 31 52.17 33.3 82.07 32.9-37.48 75.7-62.12 122-65.97-51.5-21.61-101.2-25.54-155.3-16.1zm86.5 58.17c-32.1 21.6-64.9 60.6-99.6 127.1-5.8 7.3-16.3 11.4-24.38 16.3-9.17 11.6-15.2 34.7-6.93 46.2l28.51-3.5 47 51.4c10.2-5.2 21.4-8.8 31.4-14.9l9.2 15.4c-32.1 16.1-67.8 31.7-100.7 46.2 39.9 10.6 74.4 34.5 93.6 68.4l28.3-94 17.2 5.2-13.1 43.4c70.7 51.4 183.4 74.4 236.4-6.6L404.7 425c-55.8-13.3-89.5-55.9-120.5-87.6 48.2-58 116.3-98.8 171.6-131.4l-35.9-16.2c-11.2-49.1-53.5-98.44-97.5-102.77-34.9-2.92-75.4 8.36-99.3 24.07zM25.68 149.7c21.04 91 23.9 184.3 9.93 269.1 31.82-22.7 65.99-65.1 70.59-101.7-15.82 2.4-24.68 4.9-31.97-11.6-11.61-28.6 7.82-61.5 24.51-71.9-12.3-30.1-40.7-86.1-73.06-83.9zM399 190.6L333.9 204l-36.4-43.1c42.6-12 71.5 7 101.5 29.7zm-170.2-23.9l15.8 8.7-36.9 67.3 9.6 70.1-17.8 2.5-10.5-76.1zm240.9 52.2c-14.4 8.7-15.7 9.1-28.1 16.5l42.4 61.8s-14.2-78.5-14.3-78.3zm-32 18.8c-11.5 6.8-22.9 13.7-34.1 20.8l40.5 59.9zM401.4 260c-8.5 5.4-16.9 11-25 16.7l35 43.1zm-26.8 18c-8.3 5.9-16.4 11.9-24.2 18.2l37.8 31.3zm-26.9 20.5c-7.1 5.8-14 11.9-20.6 18.3l25.3 10.4zm-38.6 38.7c10.1 10.6 19.6 21.1 30 30.8l9.2-27.4zm177.2 2.2l-45.5 59.9 33.9-7zm-105.1 6l-38 26.4c7.3 6.5 15.2 12.5 24.2 18zm64.2.7l-44.3 59c1.5.5 2.9 1 4.4 1.4l30.1-6.2zm-38.6 3.9l-36.3 41.6c8.2 4.8 17.4 9 27.8 12.6z" fill="currentColor"></path></g></svg></span>
                    <span data-v="fishPhaseRemainingShort" class="ms-1 font-monospace">3m</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `).appendTo(this.timeCtnOuter).get(0)

    this.timeCtn = this.timeCtnCompact
  }

  render() {
    if(!this.enabled) return
    this.clear()
    if(this.markers?.loaded && this.co("renderMarkers") && this.o("renderSeanames") && this.markers?.seanames) this._renderSeanames()
    if(this.markers?.loaded && this.co("renderMarkers") && this.o("renderRegions")) this._renderRegions()
    if(this.co("renderMarkers") && this.o("renderCompass")) this._renderCompass()
    if(this.o("renderGridLabels") && this.renderedLinesX) this.thr_renderGridLabels()
  }

  _renderGridLabels() {
    this.trx(ctx => {
      ctx.strokeStyle = this.o("gridLabelColor")
      ctx.fillStyle = this.o("gridLabelColor")
      // const fsize = Math.max(22, 30 / Math.max(1, this.world.vscale))
      const fsize = 22
      ctx.font = `${fsize}px windlass-extended`

      const longestText = this.ctx.measureText("20").width * 1.1 * this.world.realScale

      for (var i = 0; i < 27; i++) {
        if(i < 26 && (this.renderedLinesX[i] || this.renderedLinesX[i + 1])) { // ltr
          const label = String.fromCharCode(65 + i)
          const m = ctx.measureText(label)
          const fha = m.fontBoundingBoxAscent * this.world.realScale
          const fhd = m.fontBoundingBoxDescent * this.world.realScale

          let tx = -this.world.xZero + (i * this.gwidth) + (this.gwidth / 2) - ((m.width / 2) * this.world.realScale)
          let ty = -this.world.yZero //- (m.fontBoundingBoxDescent * this.world.realScale) //+ ((m.fontBoundingBoxAscent + m.fontBoundingBoxDescent) * this.world.realScale)

          // move y into view if necessary
          if(ty - fha < this.viewportRect.topleft[1]) {
            ty = this.viewportRect.topleft[1] + fha
          } else {
            ty -= fhd
          }

          this.fillText(label, [tx, ty])
        }
        if(i < 26 && (this.renderedLinesY[i] || this.renderedLinesY[i + 1])) { // ttb
          const label = `${i + 1}`
          const m = this.ctx.measureText(label)
          const fw = m.width * this.world.realScale
          const fhd = m.fontBoundingBoxDescent * this.world.realScale

          let tx = -this.world.xZero
          let ty = -this.world.yZero + (i * this.gheight) + (this.gheight / 2) + (((m.fontBoundingBoxAscent - m.fontBoundingBoxDescent) / 2) * this.world.realScale)

          if(tx - longestText < this.viewportRect.topleft[0]) {
            tx = this.viewportRect.topleft[0] + (longestText / 2) - (fw / 2)
          } else {
            tx -= (longestText / 2) + (fw / 2) //+ fhd
          }

          this.fillText(label, [tx, ty])
        }
      }
    })
  }

  _renderSeanames() {
    let alpha = 1.0
    let clampedScale = Math.max(1, this.world.vscale)

    if(clampedScale > this.o("startFadingAtScale")) {
      alpha = 1 - ((clampedScale - this.o("startFadingAtScale")) / (this.o("endFadingAtScale") - this.o("startFadingAtScale")))
      if(alpha < 0) return
    }

    this.trx(ctx => {
      ctx.globalAlpha = alpha
      this.markers?.seanames.forEach(md => {
        if(!md.blob) return
        const ti = [md.image_size[0] * this.world.scale / clampedScale, md.image_size[1] * this.world.scale / clampedScale]
        const tloc = this.translateOnPlane(md.location, ti)
        this.drawImage(md.blob, tloc, ti[0], ti[1])
        // this.drawCircle(md.location, 10000, { color: "purple" })
      })
    })
  }

  _renderRegions() {
    if(!this.regionCache) return false

    this.trx(ctx => {
      const clampedScale = Math.min(3, Math.max(1, this.world.vscale))
      ctx.lineWidth = Math.max(1, this.o("thickness") * clampedScale)

      this.regionCache.forEach((v, k) => {
        this.drawCircle(v.p, v.r, { color: v.c, fill: v.f })

        // if(this.o("doRender.detail") && el.radrat > this.czoomAlpha) {
        if(true) {
          this.drawX(v.p, null, null, { color: v.c })
          v.detailText ??= this.createText(`${v.name} (${k})`, { offsetY: -16, anchor: [-0.5, -1] })
          // if(el.r * 4 < el.detailText.translated("width")) return false
          // // el.detailText.opts.font = `${this.zoomVec(8, 1.5, 5)}px windlass-extended`
          v.detailText.opts.font = `${this.zoomVec(8, 3.5, 8)}px sans-serif`
          v.detailText.draw(...v.p)
        }
      })

      // level bounds
      // const pos = [937279.75, 320142.375]
      const pos = [0, 0]
      const world_size = [980000*2, 980000*2]
      const tloc = this.translateOnPlane(pos, world_size)
      const box = tloc.concat(world_size)
      this.drawBox(box, { lw: 2, color: "black", fill: "rgba(255, 0, 0, 0.1)", globalAlpha: 0.5 })

      //"SpawnLocation"
      // ;[[-282465.156, -11260.469], [-282465.156, -11077.161], [-282465.156, -10903.293], [-282465.156, -10730.027], [-282465.156, -10572.264], [-213276.0, -310790.438], [-213103.797, -310727.75], [-212940.391, -310668.281], [-212777.531, -310609.0], [-212629.328, -310555.063], [44211.434, -224792.5], [44119.805, -224951.203], [44032.855, -225101.797], [43946.207, -225251.906], [43867.344, -225388.469], [380632.219, -134791.125], [380460.0, -134853.797], [380296.625, -134913.266], [380133.75, -134972.531], [379985.563, -135026.469], [-89314.406, 392186.344], [-89173.992, 392304.063], [-89040.836, 392415.844], [-88908.102, 392527.25], [-88787.25, 392628.625], [451348.219, 272049.219], [451488.625, 272166.938], [451621.781, 272278.719], [451754.531, 272390.125], [451875.375, 272491.5]].forEach(p => {
      //   this.drawCircle(p, 20000, { fill: "rgba(255, 88, 88, 0.3)" })
      //   this.drawX(p)
      // })

      //"TunnelOfTheDamnedPortalShipSpawnLocation"
      // ;[[-279162.688, -12336.218], [-213157.359, -314261.656], [41889.285, -222209.625], [380513.594, -131319.859], [-88015.68, 388964.938], [452646.938, 268827.813]].forEach(p => {
      //   this.drawCircle(p, 20000, { fill: "rgba(255, 88, 88, 0.3)" })
      //   this.drawX(p)
      // })

      //"BP_TunnelOfTheDamnedPortalLocator_BSP_C"
      // ;[[-290538.938, -23200.875], [-225853.5, -305806.875]].forEach(p => this.drawCircle(p, 20000, { fill: "rgba(255, 220, 0, 0.3)" }))
      //"BP_TunnelOfTheDamnedPortalLocator_WLD_C"
      // ;[[55158.445, -217786.672], [392135.438, -140480.813]].forEach(p => this.drawCircle(p, 20000, { fill: "rgba(255, 220, 0, 0.3)" }))
      //"BP_TunnelOfTheDamnedPortalLocator_WSP_C"
      // ;[[-99026.445, 389302.781], [442056.813, 272736.125]].forEach(p => this.drawCircle(p, 20000, { fill: "rgba(255, 220, 0, 0.3)" }))

      // // this.searocks.get("oversea").forEach((el, i) => {
      //
      // // })
    })
  }

  _renderCompass() {
    if(!this.compassAsset.loaded) return false
    const md = this.compassAsset.opts.tex
    if(!md.blob) return
    const clampedScale = this.world.vscale
    const ti = [
      (md.image_size[0] * this.o("compassSizeRatio")) * this.world.scale / clampedScale,
      (md.image_size[1] * this.o("compassSizeRatio")) * this.world.scale / clampedScale
    ]
    const parentRect = this.worldParentRect
    const loc = this.translateViewport([parentRect.width, parentRect.height])
    const tloc = this.translateOnPlane(loc, ti, -1, -1)
    this.drawImage(md.blob, tloc, ti[0], ti[1])
    // this.drawCircle(loc, 10000, { color: "purple" })
  }
}
