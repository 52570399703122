import { WorldLayer } from "../world_layer"

export class UI extends WorldLayer {
  initPre() {
    this.dom = this.world.worldParentEl
  }

  init() {
    this.hasCanvas = false
    this.attachInitially = false
    this.enableInitially = true

    this.optreg.add("bool",  "coordsEnabled", false).onChange(nv => nv ? this.enableCoordHud() : this.disableCoordHud())
    this.optreg.add("bool",  "outer", true).onChange(nv => nv ? this.enableOuterUi() : this.disableOuterUi())
    this.optreg.add("bool",  "opts", true).onChange(nv => nv ? this.showOptionUi() : this.hideOptionUi())

    this.updateHudRefs()
    this.initListeners()
  }

  get ui() { return this }
  enableCoordHud() { $(`[data-hudctn="coords"]`).show() }
  disableCoordHud() { $(`[data-hudctn="coords"]`).hide() }

  showHUD() { super.showHUD(); if(this.o("coordsEnabled")) this.enableCoordHud() }
  hideHUD() { super.hideHUD(); this.disableCoordHud() }

  enableOuterUi() {
    $(`[data-is="navigation"]`).removeClass("d-none")
    $(`[data-is="footer"]`).removeClass("d-none")
  }

  disableOuterUi() {
    $(`[data-is="navigation"]`).addClass("d-none")
    $(`[data-is="footer"]`).addClass("d-none")
  }

  showOptionUi() {
    $(`[data-hideable-opt]`).removeClass("d-none")
  }

  hideOptionUi() {
    $(`[data-hideable-opt]`).addClass("d-none")
  }

  updateHudRefs() {
    this.ui_hud_scale = this.dom.querySelectorAll(`[data-hudval="scale"]`)
    this.ui_hud_vscale = this.dom.querySelectorAll(`[data-hudval="vscale"]`)
    this.ui_hud_x = this.dom.querySelectorAll(`[data-hudval="x"]`)
    this.ui_hud_y = this.dom.querySelectorAll(`[data-hudval="y"]`)
    this.ui_hud_rx = this.dom.querySelectorAll(`[data-hudval="rx"]`)
    this.ui_hud_ry = this.dom.querySelectorAll(`[data-hudval="ry"]`)
    this.ui_hud_region = this.dom.querySelectorAll(`[data-hudval="region"]`)
  }

  updateOption(layer, opt, newValue, oldValue, obj) {
    if(obj.boolish) {
      if(obj.type == "enum") {
        $(`[data-layer="${layer.constructor.name}"][data-layer-opt-enum="${opt}"]`).each((i, el) => {
          const nv = el.dataset.layerOptValue == obj.enumValue
          $(el).toggleClass("btn-primary", !!nv).toggleClass("btn-outline-primary", !!!nv)
        })

      }

      const outer = $(`[data-layer="${layer.constructor.name}"][data-layer-opt-toggle="${opt}"]`)
      outer.toggleClass("btn-primary", !!newValue).toggleClass("btn-outline-primary", !!!newValue)

      if(outer.data("contains") == "value") {
        outer.find(`> span`).text(obj.formattedValue)
      }
    } else {
      const elements = $(`[data-layer="${layer.constructor.name}"][data-layer-opt-prompt="${opt}"] > span`)
      if(elements.length) elements.text(obj.formattedValue)
    }
  }

  initListeners() {
    $(`[data-layer-toggle]`).on("click", ev => {
      ev.currentTarget.blur()
      this.getLayerOrToast(ev.currentTarget.dataset.layerToggle)?.toggle()
    })

    $(`[data-layer-opt-enum]`).on("click", ev => {
      ev.currentTarget.blur()
      const key = ev.currentTarget.dataset.layer
      let opt = ev.currentTarget.dataset.layerOptEnum
      let val = ev.currentTarget.dataset.layerOptValue
      const [layer, optobj] = this.getLayerWithOptionOrToast(key, opt)
      if(layer && optobj) {
        optobj.value = val
        layer[layer.afterOChange]?.()
      }
    })

    $(`[data-layer-opt-toggle]`).on("click", ev => {
      ev.currentTarget.blur()
      const key = ev.currentTarget.dataset.layer
      let opt = ev.currentTarget.dataset.layerOptToggle
      if(ev.shiftKey && ev.currentTarget.dataset.layerOptToggleShift) {
        opt = ev.currentTarget.dataset.layerOptToggleShift
      }
      const [layer, optobj] = this.getLayerWithOptionOrToast(key, opt)

      if(layer && optobj) {
        optobj.toggleValue()
        layer[layer.afterOChange]?.()
      }
    })

    $(`[data-layer-opt-prompt]`).on("click", ev => {
      ev.currentTarget.blur()
      const key = ev.currentTarget.dataset.layer
      const opt = ev.currentTarget.dataset.layerOptPrompt
      const [layer, optobj] = this.getLayerWithOptionOrToast(key, opt)

      if(layer && optobj) {
        let v = prompt(`select value for ${key}[${opt}]:`, optobj.formattedValue)

        if(v !== null) {
          optobj.value = v
          layer[layer.afterOChange]?.()
        }
      }
    })

    $(".hud-select").addClass("user-select-all cursor-text").click(ev => {
      const t = ev.currentTarget
      if(this.currentHudSelect == t) {
        delete this.currentHudSelect
      } else {
        this.currentHudSelect = t
        window.setTimeout(_ => {
          const sel = window.getSelection()
          sel.removeAllRanges()
          const range = document.createRange()
          range.selectNodeContents(t)
          sel.addRange(range)
        }, 1)
      }
    })

    $(`[data-hudoptslide]`).on("click", ev => {
      const t = $(ev.currentTarget)
      const sibling = t.next()
      const icon = t.find(".slideIcon")
      const value = t.data("hudoptslide")

      if(sibling.data("closed")) {
        icon.removeClass("bi-chevron-right").addClass("bi-chevron-left")
        sibling.removeClass("no-pointer-events")
        sibling.css({ marginLeft: `` })
        sibling.find("> div").css({ left: `` })
        sibling.data("closed", false)
      } else {
        icon.removeClass("bi-chevron-left").addClass("bi-chevron-right")
        sibling.addClass("no-pointer-events")
        sibling.data("closed", true)
        const sWidth = sibling.width()
        sibling.css({ marginLeft: `${-sWidth}px` })
        sibling.find("> div").css({ left: `${sWidth}px` })
      }
    })
  }

  getLayerOrToast(l, msg) {
    const layer = this.getLayer(l)
    if(layer) {
      return layer
    } else {
      this.toastNotification(`Failed to ${msg ?? "perform action"} for unknown layer: ${l}`, { type: "danger" })
    }
  }

  getLayerOptionOrToast(layer, o, msg) {
    const opt = layer.oo(o)
    if(opt) {
      return opt
    } else {
      this.toastNotification(`Failed to ${msg ?? `get`} unknown option '${o}' for layer: ${layer.constructor.name}`, { type: "danger" })
    }
  }

  getLayerWithOptionOrToast(l, o, msg, lmsg) {
    const layer = this.getLayerOrToast(l, lmsg ?? `${msg} option '${o}'`)
    if(!layer) return []
    const opt = this.getLayerOptionOrToast(layer, o, msg)
    return opt ? [layer, opt] : []
  }

  toastNotification(message, opts = {}) {
    let toastCtn = document.getElementById("main-toast-container")
    if(!toastCtn) {
      toastCtn = $(`<div id="main-toast-container" class="toast-container position-fixed end-0 p-3" style="z-index: 999999"></div>`).appendTo($("body")).get(0)
      toastCtn.style.top = `${$("body > nav.navbar").outerHeight()}px`
    }

    // opts.type ??= "primary"
    // opts.autohide ??= false
    let tClass = opts.tClass ?? "text-bg-primary"

    if (opts.type == "danger" || opts.type == "error") {
      tClass = "bg-danger text-bg-danger"
    } else if (opts.type == "warning") {
      tClass = "bg-warning text-bg-warning"
    } else if (opts.type == "success") {
      tClass = "bg-success text-bg-success"
    } else if (opts.type == "info") {
      tClass = "bg-info text-bg-info"
    } else if (opts.type == "primary") {
      tClass = "bg-primary text-bg-primary"
    }

    const toast = $(`
      <div class="toast align-items-center ${tClass} border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            ${ message }
          </div>
          <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `).appendTo(toastCtn)

    toast.on('hidden.bs.toast', _ => {
      toast.remove()
      if($("#main-toast-container .toast").length == 0) {
        toastCtn.remove()
      }
    })

    const topts = {}
    if(opts.autohide !== undefined) topts.autohide = opts.autohide
    if(opts.delay !== undefined) topts.delay = opts.delay
    bootstrap.Toast.getOrCreateInstance(toast.get(0), topts).show()
    return toast
  }

  changeIfDifferent(els, text) {
    els.forEach(el => {
      if (el.innerText != text) el.innerText = text
    })
  }

  async updateHudCoords(ev) {
    if(!this.ui_hud_x && !this.ui_hud_y && !this.ui_hud_rx && !this.ui_hud_ry && !this.ui_hud_scale && !this.ui_hud_vscale) return false
    const rscale = this.world.realScale
    const vscale = this.world.vscale

    this.changeIfDifferent(this.ui_hud_scale, rscale.toFixed(0))
    this.changeIfDifferent(this.ui_hud_vscale, vscale.toFixed(2))

    if(!this.o("coordsEnabled")) return false
    const rect = this.worldRect
    const scale = this.world.scale
    let x = 0, y = 0

    if (!(ev?.clientX == undefined || ev?.clientX == null)) {
      x = (ev.clientX - rect.left) / vscale
      y = (ev.clientY - rect.top) / vscale
    } else {
      const parentRect = this.worldParentRect
      x = ((parentRect.width/2) - (rect.left - parentRect.left)) / vscale
      y = ((parentRect.height/2) - (rect.top - parentRect.top)) / vscale
    }
    const rx = this.world.translateToRealX(x)
    const ry = this.world.translateToRealY(y)

    const format = function (s) { return s.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\.\d+/, "") }
    this.changeIfDifferent(this.ui_hud_x, format(x))
    this.changeIfDifferent(this.ui_hud_y, format(y))
    this.changeIfDifferent(this.ui_hud_rx, format(rx))
    this.changeIfDifferent(this.ui_hud_ry, format(ry))

    const reg = this.getRegionFromPoint(rx, ry)
    this.changeIfDifferent(this.ui_hud_region, this.pointToGridLocation(rx, ry, true)?.join("") + " " + (reg?.short ?? reg?.name ?? ""))
  }
}
