import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { Keybind } from "../../appos/component/keyboard/keybind"

// Connects to data-controller="input--type-keybind"
export default class extends Controller {
  static targets = ["input", "resetButton"]
  static values = {
    current: String,
    remoteUrl: String,
    defaultBind: String,
  }

  connect() {
    if(this.currentValue) this.setHumanizedValue(this.currentValue)
    if(this.resetButtonTarget.title) this.resetButtonTarget.title += ` (${this.defaultBindValue ? Keybind.humanizeKey(this.defaultBindValue) : "<unbound>"})`
  }

  setHumanizedValue(v) {
    this.currentValue = v
    this.inputTarget.value = Keybind.humanizeKey(v)
  }

  captureHotkey(ev) {
    ev.currentTarget.blur()
    this.activeModal?.destroy()
    this.activeModal = new CaptureModal().open()
    this.activeModal.onSelect(v => {
      this.setHumanizedValue(v)
      this.remoteSave()
    }).catch(_ => {})
    this.activeModal.onClose(ev => { delete this.activeModal })
    ev.preventDefault()
  }

  resetHotkey(ev) {
    ev.currentTarget.blur()
    this.setHumanizedValue(this.defaultBindValue)
    this.remoteSave()
    ev.preventDefault()
  }

  clearHotkey(ev) {
    ev.currentTarget.blur()
    this.setHumanizedValue("")
    this.remoteSave()
    ev.preventDefault()
  }

  remoteSave() {
    if(!this.remoteUrlValue) return false

    this.inputTarget.classList.remove("is-valid", "is-invalid")
    this.inputTarget.classList.add("border-warning")

    this.setRemoteValue(this.inputTarget.name, this.currentValue).then(j => {
      this.inputTarget.classList.remove("border-warning")
      this.inputTarget.classList.add("is-valid")
      setTimeout(_ => {
        this.inputTarget.classList.remove("is-valid")
      }, 2500)
    }).catch(err => {
      this.inputTarget.classList.remove("border-warning")
      this.inputTarget.classList.add("is-invalid")
      console.error("Failed to save setting", this.inputTarget.name, "with value", this.inputTarget.value, "due to", err)
      alert(`Sorry but an error occurred: ${err?.message ?? err}`)
    })
  }

  async setRemoteValue(sopt, sval) {
    const request = new FetchRequest("post", this.remoteUrlValue, {
      body: JSON.stringify({ sopt, sval })
    })
    const response = await request.perform()
    if (response.ok) {
      return await response.json
    } else {
      throw `${response.statusCode}: ${response.response.statusText}`
    }
  }
}

class CaptureModal {
  constructor(opts = {}) {
    this.opts = Object.assign({}, {
      allowEscape: false,
      requireModifier: true,
    }, opts)
    this.dom = this._buildDOM()
    this.handle = bootstrap.Modal.getOrCreateInstance(this.dom, opts.modal)

    this.iKeyInput = this.dom.querySelector(`[name="keyInput"]`)
    this.iKeyInput.addEventListener("keydown", ev => this.handleKeydown(ev))
    this.vWarning = this.dom.querySelector(`[data-role="warning"]`)

    this.onShow(ev => this.iKeyInput.focus())
    this.onClose(ev => this.destroy())
    document.body.append(this.dom)

    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  open() {
    this.handle.show()
    return this
  }

  anyModkey(ev) { return ev.ctrlKey || ev.metaKey || ev.altKey || ev.shiftKey }

  handleKeydown(ev) {
    ev.stopImmediatePropagation()
    ev.stopPropagation()
    ev.preventDefault()

    if(Keybind.modkeys.includes(ev.key)) {
      this.iKeyInput.value = this.keyEventToString(ev, true) + "+"
      this.iKeyInput.classList.remove("text-danger")
      this.vWarning.classList.remove("text-danger")
    } else {
      if(!this.opts.allowEscape && ev.code == "Escape" && !this.anyModkey(ev)) return this.close()
      this.iKeyInput.value = this.keyEventToString(ev, true)
      if(!this.opts.requireModifier || this.anyModkey(ev)) {
        this.iKeyInput.disabled = "disabled"
        this.resolve(this.keyEventToString(ev, false))
        this.close()
      } else {
        this.iKeyInput.classList.add("text-danger")
        this.vWarning.classList.add("text-danger")
      }
    }
  }

  keyEventToString(ev, humanize = false) {
    const parts = []
    if(ev.ctrlKey) parts.push("Control")
    if(ev.altKey) parts.push("Alt")
    if(ev.shiftKey) parts.push("Shift")
    if(ev.metaKey) parts.push("Meta")
    if(!Keybind.modkeys.includes(ev.key)) parts.push(ev.code)

    return humanize ? Keybind.humanizeKey(parts) : parts.join("+")
  }

  onSelect(cb) { return this.promise.then(cb) }
  onCancel(cb) { return this.promise.catch(cb) }
  onBeforeShow(cb) { this.dom.addEventListener("show.bs.modal", cb); return this }
  onShow(cb) { this.dom.addEventListener("shown.bs.modal", cb); return this }
  onBeforeClose(cb) { this.dom.addEventListener("hide.bs.modal", cb); return this }
  onClose(cb) { this.dom.addEventListener("hidden.bs.modal", cb); return this }

  close() {
    this.handle.hide()
    return this
  }

  destroy() {
    this.reject()
    this.handle.dispose()
    this.dom.remove()
    delete this.dom
    delete this.handle
  }

  _buildDOM() {
    const template = document.createElement("template")
    template.innerHTML = `
      <div class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form action="" method="post" class="form-horizontal m-0" data-turbo="false">
              <div class="modal-header">
                <h5 class="modal-title">Set keybind</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <input type="text" name="keyInput" class="form-control">
                <div class="text-info mt-2" data-role="warning">
                  <i class="bi bi-info-circle"></i>
                  <span class="text-small"> this bind requires a modifier key (such as shift, alt, ctrl or meta)</span>
                </div>
              </div>
            </form>
         </div>
        </div>
      </div>
    `
    return template.content.firstElementChild
  }
}
