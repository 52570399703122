export class ExternalPromise {
  constructor() {
    this.resolved = false
    this.raw = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
    this.raw.finally(_ => { this.resolved = true })
  }

  then(...args) { return this.raw.then(...args) }
  catch(...args) { return this.raw.catch(...args) }
  finally(...args) { return this.raw.finally(...args) }
}
