export class Keybind {
  static nonGlobal = ["INPUT", "TEXTAREA"]
  static actionKeys = ["Action", "ActionLeft", "ActionRight"]
  static controlKeys = ["Control", "ControlLeft", "ControlRight"]
  static altKeys = ["Alt", "AltLeft", "AltRight"]
  static shiftKeys = ["Shift", "ShiftLeft", "ShiftRight"]
  static metaKeys = ["Meta", "MetaLeft", "MetaRight"]
  static modkeys = [
    ...this.controlKeys,
    ...this.altKeys,
    ...this.shiftKeys,
    ...this.metaKeys,
  ]

  static normalizeMap = {
    "*": "Action+",
    "action": "Action",
    "^": "Control",
    "ctrl": "Control",
    "!": "Alt",
    "opt": "Alt",
    "option": "Alt",
    "+": "Shift",
    "#": "Meta",
    "win": "Meta",
    "cmd": "Meta",
    "command": "Meta",
  }

  static get isMac() { return navigator.platform.toUpperCase().indexOf('MAC') >= 0 }

  static normalizeBindString(str) {
    const chunks = str.split("+").map(c => c.trim()).sort((a, b) => {
      const indexA = this.modkeys.indexOf(a)
      const indexB = this.modkeys.indexOf(b)

      if (indexA === -1 && indexB === -1) return 0 // Both not in staticArray
      if (indexA === -1) return 1 // `a` not in staticArray
      if (indexB === -1) return -1 // `b` not in staticArray
      return indexA - indexB // Compare their indices
    })

    return chunks.join("+")
  }

  static humanizeKey(key) {
    if(!Array.isArray(key)) key = key.split("+")

    return key.map(k => {
      k = k.trim()
      if(k.startsWith("Key")) return k.slice(3)
      if(k.startsWith("Digit")) return k.slice(5)
      if(k == "Action") return "CommandOrControl"
      return k
    }).join("+")
  }


  constructor(key, opts = {}, fn) {
    if(typeof opts == "function" && !fn) [fn, opts] = [opts, {}];
    this.opts = Object.assign({}, {
      global: false,
    }, opts)
    this.fn = fn
    this.enabled = true
    this.chunks = key.split("+").map(k => k.trim())
    this.key = this.chunks.pop()
    this.ctrlKey = this.chunks.some(x => this.constructor.controlKeys.includes(x))
    this.altKey = this.chunks.some(x => this.constructor.altKeys.includes(x))
    this.shiftKey = this.chunks.some(x => this.constructor.shiftKeys.includes(x))
    this.metaKey = this.chunks.some(x => this.constructor.metaKeys.includes(x))
    this[this.isMac ? "metaKey" : "ctrlKey"] = this.chunks.some(x => this.constructor.actionKeys.includes(x))
    this.nonGlobal = [...this.constructor.nonGlobal]
  }

  setOpt(opt, value) { this.opts[opt] = value; return this }
  global(set = true) { this.opts.global = set; return this }
  press(fn) { this.fn = fn; return this }
  enable() { this.enabled = true; return this }
  disable() { this.enabled = false; return this }

  get isMac() { return this.constructor.isMac }

  handle(ev) {
    if(!this.enabled) return undefined
    if(ev.type != "keydown") return undefined

    // input
    if(!this.opts.global && this.nonGlobal.includes(ev.target.tagName)) return false

    // modkeys
    if(this.ctrlKey && !ev.ctrlKey) return false
    if(this.altKey && !ev.altKey) return false
    if(this.shiftKey && !ev.shiftKey) return false
    if(this.metaKey && !ev.metaKey) return false

    // hotkey
    if(ev.code != this.key) return false

    this.fn?.(ev, this)
    return true
  }
}
