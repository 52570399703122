import * as AppOS from "../../appos"

const Component = class extends AppOS.Component {
  static name = "Sot__IngameTime"

  init() {
    this.timers = []
  }

  documentLoad() {
  }

  pageLoad() {
    this.timers.forEach(t => t.destroy())
    this.timers = []

    const dim = { w: 455, h: 555, he: 1000 }

    $(`[data-appos-controller="SotIngameTime"]`).each((i, el) => {
      const timer = new SotTimerElement(el)
      this.timers.push(timer.start())

      if (el.dataset.resizeWindow) {
        window.resizeTo(dim.w, dim.h)
        timer.setV("rawOuter", outer => {
          outer.addEventListener("toggle", ev => {
            window.resizeTo(dim.w, outer.open ? dim.he : dim.h)
          })
        })
      }
    })
  }
}

class SotTimerElement {
  constructor(el, opts = {}) {
    this.el = el
    this.opts = Object.assign({}, {
      // clockHandlesFilter: n => { return n % 2 == 0 }, // even
      // clockHandlesFilter: n => { return n % 2 == 1 }, // odd
      // clockHandlesFilter: n => { console.log(n, (n) % 3 != 0); return (n+1) % 3 != 0 }, // main
    }, opts)
    this.timer = null
    this.createHandles()
  }

  updateElement(el) {
    const wasRunning = !!this.timer
    wasRunning && this.stop()
    this.el = el
    wasRunning && this.start()
    this.render()
    return this
  }

  destroy() {
    this.stop()
  }

  calculateCoordinates(radius) {
    // const coordinates = []
    // for (let angle_deg = 0; angle_deg < 360; angle_deg += 30) {
    //   let angle_rad = angle_deg * Math.PI / 180
    //   let x = radius * Math.cos(angle_rad)
    //   let y = radius * Math.sin(angle_rad)
    //   coordinates.push({ x: x, y: y })
    // }
    // return coordinates

    const coordinates = []
    const angleIncrement = Math.PI / 12 // 15 degrees in radians

    for (let hour = 1; hour <= 24; hour++) {
        const angle = angleIncrement * (hour - 6); // Starting from 3 o'clock
        const x = 0 + radius * Math.cos(angle)
        const y = 0 + radius * Math.sin(angle)
        coordinates.push({ x, y })
    }

    return coordinates
  }

  createHandles() {
    this.setV("clockFace", el => {
      ["clockHandles", "clockNotches"].forEach(what => {
        this.setV(what, ch => {
          let mdim = 0
          for (var i = 0; i < 24; i++) {
            if(this.opts?.[`${what}Filter`]?.(i)) continue;
            const handle = $("<div>").addClass("position-absolute").attr("data-i", i)
            if(what == "clockHandles") {
              handle.text(i+1)
            }
            $(ch).append(handle)
            mdim = Math.max(mdim, Math.max(handle.outerWidth(), handle.outerHeight()))
          }
          let coords = null
          if (what == "clockNotches") {
            coords = this.calculateCoordinates((el.offsetWidth * 0.5) - (mdim / 2))
          } else {
            coords = this.calculateCoordinates((el.offsetWidth * 0.5) - (mdim / 1))
          }
          coords.forEach((coord, i) => {
            const handle = $(ch).find(`div[data-i=${i}]`)
            let x = coord.x - (handle.outerWidth() / 2)
            let y = coord.y - (handle.outerHeight() / 2)
            handle.css({
              "top": `${(y)}px`, //  + (handle.height() * ((i * 30) % 90 / 90))
              "left": `${(x)}px`, //  + (handle.width() * ((i * 30) % 90 / 90))
            })
            if(what == "clockNotches") {
              handle.get(0)?.style?.setProperty('--rotate', `${(i + 1) * 15}deg`)
            }
          })
        })
      })
    })
  }

  start() {
    if(this.timer) this.stop()
    this.render()
    this.timer = setInterval(_ => this.render(), 1000)
    this.setV("clockFace", el => el.offsetHeight)
    // setTimeout(_ => {
      this.setV("clockFace", el => { el.classList.add("running") })
    // }, 1)
    return this
  }

  stop() {
    clearInterval(this.timer)
    this.timer = null
    this.setV("clockFace", el => { el.classList.remove("running") })
  }

  render() {
    const t = SotIngameTime.now()
    // this.el.innerHTML = `Hi: ${SotIngameTime.toHumanString(t)} ${}`
    this.setV("raw", JSON.stringify(t, null, 2))
    this.setV("formatted", SotIngameTime.toHumanString(t))
    this.setV("phase", t.phase)
    this.setV("fishPhase", t.fishPhase)
    this.setV("fishPhaseIcon", el => {
      el.classList.toggle("text-achievement", t.fishPhase == "day")
      el.classList.toggle("text-athena", t.fishPhase != "day")
    })
    this.setV("fishPhaseRemainingShort", el => {
      const nfp = t.nextFishPhase
      if(nfp <= 99) {
        el.innerText = nfp
      } else {
        el.innerText = `${Math.ceil(nfp / 60)}m`
      }
    })
    this.setV("month", t.month)
    this.setV("day", SotIngameTime.ordinalize(t.day))
    this.setV("hour", SotIngameTime.pad(t.hour))
    this.setV("minute", SotIngameTime.pad(t.minute))
    this.setV("clockFace", el => {
      const deg = 360 * (1 - t.dayReal % 1)
      const ldeg = el.dataset.lastDeg ?? 0
      el.dataset.lastDeg = deg

      if(Math.abs(ldeg - deg) > 300) {
        // rolled over, skip animation
        el.classList.remove("running")
        el.style.setProperty('--rotate', `${deg}deg`)
        el.offsetHeight
        el.classList.add("running")
      } else {
        el.style.setProperty('--rotate', `${deg}deg`)
      }

      this.setV("clockHandles", ch => {
        ch.style.setProperty('--rotate', `${360 - deg}deg`)
      })
      this.setV("clockNotches", ch => {
        ch.style.setProperty('--rotate', `${360 - deg}deg`)
      })
      // if(el.dataset.lastDeg != deg) {

      // }
      // el.dataset.lastDeg = el.dataset.lastDeg ??
      // console.log(deg, parseFloat(el.style.getPropertyValue("--rotate")))
    })
  }

  setV(k, v) {
    const el = this.el.querySelector(`[data-v="${k}"`)
    if(!el) return false
    if(typeof v == "function") {
      return v(el)
    } else if (el.innerHTML != v) {
      return el.innerHTML = v
    }
  }
}

class SotIngameTime {
  static RT_SECONDS_PER_MINUTE = 60
  static RT_MINUTES_PER_HOUR = 60
  static RT_SECONDS_PER_HOUR = this.RT_MINUTES_PER_HOUR * this.RT_SECONDS_PER_MINUTE

  static IG_MINUTES_PER_HOUR = 60
  static IG_HOURS_PER_DAY = 24
  static IG_DAYS_PER_MONTH = 30
  static IG_MONTHS_PER_YEAR = 8
  static IG_MINUTES_PER_DAY = this.IG_MINUTES_PER_HOUR * this.IG_HOURS_PER_DAY

  static RT_SECONDS_PER_IG_MINUTE = 1
  static RT_SECONDS_PER_IG_HOUR = this.RT_SECONDS_PER_IG_MINUTE * this.IG_MINUTES_PER_HOUR
  static RT_SECONDS_PER_IG_DAY = this.IG_HOURS_PER_DAY * this.RT_SECONDS_PER_IG_HOUR
  static RT_SECONDS_PER_IG_MONTH = this.IG_DAYS_PER_MONTH * this.RT_SECONDS_PER_IG_DAY

  static igHM(h, m) {
    if(typeof h == "string") {
      const c = h.split(":")
      h = parseInt(c[0])
      m = parseInt(c[1])
    }
    return h * this.IG_MINUTES_PER_HOUR + m
  }

  static IG_DAY_PHASES = [
    { t: this.igHM("00:00"), phase: "night", effective: "night", marker: "midnight" }, // default
    { t: this.igHM("03:00"), phase: "twilight-astronomical", effective: "night", marker: "astronomical-dawn" }, // astronomical dawn - sun begins to lighten the sky
    { t: this.igHM("04:00"), phase: "twilight-nautical", effective: "night", marker: "nautical-dawn" }, // nautical dawn - horizon can be distinguished
    { t: this.igHM("05:00"), phase: "sunrise", effective: "day" }, // sun begins to rise above horizon
    { t: this.igHM("05:30"), phase: "morning", effective: "day" }, // sun has fully risen above horizon
    { t: this.igHM("11:45"), phase: "noon", effective: "day" }, // sun is at highest point (technically at 12 but we give it 30sec)
    { t: this.igHM("12:15"), phase: "afternoon", effective: "day" }, // sun starts to set
    { t: this.igHM("17:00"), phase: "evening", effective: "day" }, // evening begins
    { t: this.igHM("20:30"), phase: "sunset", effective: "day" }, // sun begins to set
    { t: this.igHM("22:10"), phase: "twilight-nautical", effective: "night", marker: "sunset-end" }, // sun has fully set below horizon
    { t: this.igHM("22:20"), phase: "twilight-astronomical", effective: "night", marker: "nautical-dusk" }, // nautical dusk - horizon stops being distinguishable
    { t: this.igHM("22:45"), phase: "night", effective: "night", marker: "astronomical-dusk" }, // astronomical dusk - sky has reached max darkness
  ]

  static IG_FISH_DAY_START = this.igHM("07:00")
  static IG_FISH_DAY_END = this.igHM("22:29")

  // static IG_SHADOWSKELLI_DAY_START = this.igHM("07:00")
  // static IG_SHADOWSKELLI_DAY_END = this.igHM("22:29")

  // ==============
  // = StormIndex =
  // ==============
  // static IG_STORM_ZERO_TIME = new Date(Date.UTC(1990, 5 - 1, 31, 22, 50, 0))
  // static IG_STORM_ZERO_TIME = new Date(Date.UTC(2013, 11 - 1, 3, 0, 0, 0))
  static IG_STORM_ZERO_TIME = new Date(Date.UTC(1972, 10 - 1, 30, 0, 0, 0))
  static IG_STORM_CYCLE_SECONDS = 361000
  static IG_STORM_STEP_SECONDS = 1000

  static stormIndex(at = null) {
    at = at ?? new Date()
    let deltaSeconds = Math.abs(at - this.IG_STORM_ZERO_TIME) / 1000
    const stormCyclePos = deltaSeconds % this.IG_STORM_CYCLE_SECONDS
    const stormCycleIndex = stormCyclePos / this.IG_STORM_STEP_SECONDS
    return stormCycleIndex
  }

  static stormLastZeroAt(at = null) {
    at = at ?? new Date()
    return new Date(at.getTime() - (this.stormIndex(at) * this.IG_STORM_STEP_SECONDS * 1000))
  }

  static stormNextZeroAt(at = null, steps = 1, includeLast = false) {
    at = at ?? new Date()
    const ptr = this.stormLastZeroAt(at)

    if (steps == 1) {
      return new Date(ptr.getTime() + (this.IG_STORM_CYCLE_SECONDS * 1000))
    }

    const res = []
    if(includeLast) res.push(ptr)

    for (var i = 0; i < steps; i++) {
      res.push(new Date(ptr.getTime() + ((i + 1) * (this.IG_STORM_CYCLE_SECONDS * 1000))))
    }

    return res
  }

  // ==============
  // = Formatting =
  // ==============
  static ordinalized(number) {
    number %= 100
    if(number > 13) number %= 10

    if(number == 1) return "st"
    if(number == 2) return "nd"
    if(number == 3) return "rd"
    return "th"
  }

  static ordinalize(number) {
    return number + this.ordinalized(number)
  }

  static pad(number, length = 2, p = "0") {
    let res = number + ""
    while(res.length < length) {
      res = p + res
    }
    return res
  }

  static toHumanString(rm) {
    return `${this.ordinalize(rm.day)} ${this.pad(rm.hour)}:${this.pad(rm.minute)}`
  }

  // =======
  // = API =
  // =======
  static now() {
    return this.at(new Date())
  }

  static at(t) {
    const result = new Map()
    result.rtSecondsToday = t.getUTCSeconds() + t.getUTCMinutes() * this.RT_SECONDS_PER_MINUTE + t.getUTCHours() * this.RT_SECONDS_PER_HOUR
    let igMinutes = result.rtSecondsToday

    result.monthReal = igMinutes / this.RT_SECONDS_PER_IG_MONTH
    igMinutes %= this.RT_SECONDS_PER_IG_MONTH

    result.dayReal = igMinutes / this.RT_SECONDS_PER_IG_DAY
    igMinutes %= this.RT_SECONDS_PER_IG_DAY

    result.hourReal = igMinutes / this.RT_SECONDS_PER_IG_HOUR
    igMinutes %= this.RT_SECONDS_PER_IG_HOUR

    result.minuteReal = igMinutes

    result.month = Math.floor(result.monthReal + 1)
    result.day = Math.floor(result.dayReal + 1)
    result.hour = Math.floor(result.hourReal)
    result.minute = result.minuteReal
    result.minutesOfDay = result.minute + result.hour * this.IG_MINUTES_PER_HOUR

    result.phaseFull = this.timeOfDay(result)
    result.phase = result.phaseFull.phase
    result.fishPhase = this.fishPhase(result)
    result.nextFishPhase = this.nextFishPhase(result)
    // result.timeOfDay = this.timeOfDay(result)
    // result.stormIndex = this.stormIndex()
    // result.stormLastZero = this.stormLastZeroAt()
    // result.stormNextZero = this.stormNextZeroAt(null, 7)

    return result
  }

  static fishPhase(rm) {
    if(rm.minutesOfDay >= this.IG_FISH_DAY_START && rm.minutesOfDay <= this.IG_FISH_DAY_END) {
      return "day"
    } else {
      return "night"
    }
  }

  static nextFishPhase(rm) {
    if(rm.minutesOfDay >= this.IG_FISH_DAY_START && rm.minutesOfDay <= this.IG_FISH_DAY_END) {
      return this.IG_FISH_DAY_END - rm.minutesOfDay
    } else if(rm.minutesOfDay >= this.IG_FISH_DAY_END) {
      return this.IG_MINUTES_PER_DAY - rm.minutesOfDay + this.IG_FISH_DAY_START
    } else {
      return this.IG_FISH_DAY_START - rm.minutesOfDay
    }
  }

  static skellyPhase(rm) {
    if(rm.minutesOfDay >= this.IG_SHADOWSKELLI_DAY_START && rm.minutesOfDay <= this.IG_SHADOWSKELLI_DAY_END) {
      return "day"
    } else {
      return "night"
    }
  }

  static timeOfDay(rm) {
    let timeOfDay = null

    for (let phase of this.IG_DAY_PHASES) {
      if(rm.minutesOfDay >= phase.t) {
        timeOfDay = phase
      } else {
        break
      }
    }

    return timeOfDay
  }
}

AppOS.Application?.availableComponents?.push?.(Component)
export { Component, SotIngameTime, SotTimerElement }
