export class WorldCursor {
  constructor(world) {
    this.world = world
    this.default = "grab"
    this.forces = new Map()
    this.filters = new Map()
  }

  get effective() {
    let effectiveCursor = this.default
    let currentWeight = 0

    for (const [origin, [force, cursor]] of this.forces) {
      let eforce = force
      if(typeof eforce == "function") eforce = eforce()
      if(eforce < currentWeight) continue;
      // console.log("cursor", cursor, "with force", eforce, "from", origin)
      effectiveCursor = cursor
      currentWeight = eforce
    }

    return effectiveCursor
  }

  apply() {
    if(this.world.worldEl.style.cursor != this.effective) {
      this.world.worldEl.style.cursor = this.effective
    }
    return this
  }

  startGrab() {
    this.force("panzoom", "grabbing", 10)
  }

  updateGrab() {
    this.force("panzoom", "grabbing", this.world.mouseDeltaThresholdAny(1) ? 1000 : 10)
  }

  endGrab() {
    this.force("panzoom", null)
  }

  force(key, value, force = 100, apply = true) {
    if(value === null) {
      if(!this.forces.has(key)) return this
      this.forces.delete(key)
    } else {
      this.forces.set(key, [force, value])
    }
    return apply ? this.apply() : this
  }

  addFilter(target, selectorFunction, opts = {}) {
    this.filters.set(target, new CursorFilter(this, target, selectorFunction, opts))
  }

  handleParentMouseMove(ev) {
    for (const [_, f] of this.filters) {
      if(f.handleMove(ev) === false) return false
    }
  }

  handleParentClick(ev) {
    for (const [_, f] of this.filters) {
      if(f.handleClick(ev) === false) return false
    }
  }
}



class CursorFilter {
  constructor(cursor, target, selectorFunction, opts = {}) {
    this.cursor = cursor
    this.target = target
    this.selectorFunction = selectorFunction
    this.opts = Object.assign({}, {
      cursorForce: 500,
    }, opts)
    this.inFocus = new Map()
  }

  find(ev) { return this.target[this.selectorFunction](ev) }

  mouseover(el, ev) {
    if(this.opts.onHover?.(el, ev, this) === false) return false
  }

  mouseout(el, ev) {
    if(this.opts.onBlur?.(el, ev, this) === false) return false
  }

  click(el, ev) {
    if(this.opts.onClick?.(el, ev, this) === false) return false
  }

  rightClick(el, ev) {
    if(this.opts.onRightClick?.(el, ev, this) === false) return false
  }

  handleClick(ev) {
    const t = this.find(ev)
    if(!t) return undefined

    if(ev.button === 2) {
      return this.rightClick(t, ev)
    } else if (ev.button === 0) {
      return this.click(t, ev)
    } else {
      console.warn(`unknown mouse button ${ev.button}, triggering as leftClick`)
      return this.click(t, ev)
    }
  }

  handleMove(ev) {
    const t = this.find(ev)
    let retval = undefined

    // blur all except t
    this.inFocus.forEach((_, el) => {
      if(t && t == el) return
      this.mouseout(el, ev)
      this.inFocus.delete(el)
    })

    // focus match
    if(t) {
      if(!this.inFocus.has(t)) {
        if(this.mouseover(t, ev) === false) {
          retval = false
        } else {
          this.inFocus.set(t, true)
        }
      }
    }

    if(this.opts.hoverCursor) {
      this.cursor.force(this, this.inFocus.size ? this.opts.hoverCursor : null, this.opts.cursorForce)
    }

    return retval
  }
}
