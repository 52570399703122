export class ContextMenu {
  constructor(opts = {}, ...args) {
    this.opts = Object.assign({}, opts)
    if(this.opts.assign) Object.entries(this.opts.assign).forEach(([key, value]) => this[key] = value)
    this.buttons = new Map()
    this.init?.(...args)
  }

  get boundary() { return this.opts.boundary ?? document.body }

  destroy() {
    this.ctn?.outer.remove()
    delete this.ctn
    this.opts.onDestroy?.(this)
    this.opts.onDestroyManager?.(this)
  }

  close() {
    if(!this.ctn) return this
    this.ctn.hide()
    return this
  }

  open(ev) {
    const ctn = this.build()
    const wrect = this.layer.world.worldParentRect

    ctn.outer.style.top = `${(ev?.pageY ?? wrect.top) - wrect.top}px`
    ctn.outer.style.left = `${(ev?.pageX ?? wrect.left) - wrect.left}px`

    ctn.show()

    return this
  }

  addButton(id, name, opts = {}) {
    if(!id) {
      this.buttonIndex ??= -1
      this.buttonIndex += 1
      id = `btn_${this.buttonIndex}`
    }
    this.buttons.set(id, ["button", id, name, opts])
    return id
  }

  addHeader(name, opts) {
    this.headerIndex ??= -1
    this.headerIndex += 1
    const id = `header_${this.headerIndex}`
    this.buttons.set(id, ["header", id, name, opts])
    return id
  }

  addDivider(opts = {}) {
    this.dividerIndex ??= -1
    this.dividerIndex += 1
    const id = `div_${this.dividerIndex}`
    this.buttons.set(id, ["divider", id, opts])
    return id
  }

  fitMenuIntoView() {
    const boundary = this.boundary
    const currentPosition = $(this.ctn.outer).position()
    const currentDimensions = { width: $(this.ctn.list).outerWidth(), height: $(this.ctn.list).outerHeight() }
    const vpScrollWidth = boundary.offsetWidth - boundary.clientWidth
    const vpScrollHeight = boundary.offsetHeight - boundary.clientHeight

    let outBelow = boundary.clientHeight - currentDimensions.height - currentPosition.top - 20 + document.documentElement.scrollTop
    outBelow = outBelow < 0 ? -outBelow : null
    let outRight = boundary.clientWidth - currentDimensions.width - currentPosition.left - vpScrollWidth - 20
    outRight = outRight < 0 ? -outRight : null

    // move to left of cursor
    if(outRight != null) this.ctn.outer.style.left = `${currentPosition.left - currentDimensions.width}px`
    // move up by delta
    if(outBelow != null) this.ctn.outer.style.top = `${currentPosition.top - outBelow}px`
  }

  build() {
    if(this.ctn) return this.ctn
    this.ctn = this.buildContainer().attach()
    return this.ctn
  }

  buildButtonNode(type, ...args) {
    if(type == "button") {
      return this.createDropdownItem(...args)
    } else if(type == "divider") {
      return this.createDivider(...args)
    } else if(type == "header") {
      return this.createDropdownHeader(...args)
    } else {
      throw(`unknown button node type ${type}`)
    }
  }

  buildContainer() {
    const ctn = {}
    ctn.ref = this
    ctn.outer = this.createOuter()
    ctn.dropdown = this.createDropdownContainer()
    ctn.btn = this.createDropdownButton()
    ctn.list = this.createDropdownList()

    if(ctn.dropdown) {
      if(ctn.btn) ctn.dropdown.append(ctn.btn)
      if(ctn.list) {
        this.buttons.forEach(btn => ctn.list.append(this.buildButtonNode(...btn)))
        ctn.dropdown.append(ctn.list)
      }
      ctn.outer.append(ctn.dropdown)
    }

    ctn.attach = function() { this.ref.boundary.append(this.outer); return this }.bind(ctn)
    ctn.show = function(...args) {
      this.list.classList.add("show")
      this.ref.fitMenuIntoView()
    }.bind(ctn)
    ctn.hide = function(...args) {
      this.list.classList.remove("show")
      this.ref.destroy()
    }.bind(ctn)

    return ctn
  }

  createOuter() {
    const el = document.createElement("div")
    el.classList.add("contextmenu")
    el.style.position = "absolute"
    return el
  }

  createDropdownContainer() {
    const el = document.createElement("div")
    el.classList.add("dropdown")
    return el
  }

  createDropdownButton() {
    const el = document.createElement("button")
    el.classList.add("btn", "btn-secondary", "dropdown-toggle", "d-none")
    // el.dataset.bsBoundary = "window"
    el.dataset.bsToggle = "dropdown"
    return el
  }

  createDropdownList() {
    const el = document.createElement("ul")
    el.classList.add("dropdown-menu")
    return el
  }

  // ------------------------------------------

  createDivider(id, opts = {}) {
    const el = document.createElement("div")
    el.classList.add("dropdown-divider")
    if(opts.class) el.classList.add(...opts.class.split(" "))
    if(opts.style) el.style = opts.style
    return el
  }

  createDropdownHeader(id, name, opts = {}) {
    const el = document.createElement("li")
    const h = document.createElement("h6")
    h.classList.add("dropdown-header")
    if(opts.class) h.classList.add(...opts.class.split(" "))
    if(opts.style) h.style = opts.style
    h.innerText = name
    el.append(h)
    return el
  }

  createDropdownItem(id, name, opts = {}) {
    const el = document.createElement("li")
    if(opts.click) el.addEventListener("click", ev => {
      const res = (opts.click === true ? this[id](ev) : opts.click(ev))
      if(res !== false) this.close()
    })
    if(opts.liclass) el.classList.add(...opts.liclass.split(" "))
    if(opts.listyle) el.style = opts.listyle

    const a = document.createElement("a")
    a.href = "#"
    a.classList.add("dropdown-item")
    a.innerText = name
    if(opts.class) a.classList.add(...opts.class.split(" "))
    if(opts.style) a.style = opts.style

    if(opts.icon) {
      let icopts = opts.icon
      if(typeof icopts == "string") icopts = { ico: icopts }
      const ico = document.createElement("i")
      ico.classList.add("bi", `bi-${icopts.ico}`)
      if(icopts.class) ico.classList.add(...icopts.class.split(" "))
      if(icopts.style) ico.style = icopts.style
      a.prepend(ico)
    }

    el.append(a)
    return el
  }
}
